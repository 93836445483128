import { primary, secondary, white } from '../../../colors';
import Color from 'color';

import { css as original } from '@custom/defaults/theme/Components/Button/styles';
import { merge } from 'lodash';

export const css = merge(original, {
  button: {
    '&.ant-btn-primary:not([disabled])': {
      background: `linear-gradient(180deg, ${Color(secondary)
        .darken(0.1)
        .rgb()
        .string()} 0%, ${secondary} 100%) !important`,
      '&:before': {
        background: `linear-gradient(180deg, ${secondary} 0%, ${Color(secondary)
          .lighten(0.3)
          .rgb()
          .string()} 100%) !important`,
      },
    },
    '&.secondary': {
      background: `linear-gradient(180deg, ${Color(primary)
        .darken(0.1)
        .rgb()
        .string()} 0%, ${primary} 100%) !important`,
      color: `${white} !important`,
      borderColor: 'transparent',
      position: 'relative',
      overflow: 'hidden',
      border: 0,
    },
  },
});

export default css;
