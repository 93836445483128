import * as React from 'react';
import { OPTIONS } from '../config';
import Script from 'next/script';

export const BlinkTitle: React.FC = () => {
  const isBlink = !!OPTIONS['blinkTitle'];

  if (!isBlink) return null;

  return (
    <Script
      id='blink'
      strategy='lazyOnload'
      dangerouslySetInnerHTML={{
        __html: `

                const faviconItem = document.querySelectorAll('[data-favicon]');
                const baseFavicon = faviconItem[0]?.getAttribute('data-base-icon');
                const blurFavicon = faviconItem[0]?.getAttribute('data-blur-icon');
            
                const origTitle = document.title;
                let blurInterval = null;
            
                window.onblur = function () {
                    if (blurInterval) clearInterval(blurInterval);
                    if(/(loanApps\\/create)|(registration)/.test(window.location.href))
                    {
                        blurInterval = setInterval(function (){
                            if (document.visibilityState === 'visible') { return }
                            if (document.title ===  origTitle) {
                                newHeadInfo()
                            } else {
                                oldHeadInfo(true)
                            }
                        },1800);
                    }
                };
                window.onfocus = function () {
                    oldHeadInfo(false)
                };
            
                function oldHeadInfo(clear) {
                    if (!clear) {
                        if (blurInterval) clearInterval(blurInterval);
                    }
            
                    document.title = origTitle;
                    faviconItem[0]?.setAttribute('href', baseFavicon)
                }
            
                function newHeadInfo() {
                    document.title = 'Не забудьте заполнить анкету!';
                    faviconItem[0]?.setAttribute('href', blurFavicon)
                }
            `,
      }}
      onError={(e) => {
        console.error('Script failed to load', e);
      }}
    />
  );
};
