import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';

class SbpService extends Entity {
  public async banks() {
    this.storeEntity = 'sbp';
    return await this.fetch('', '/banks');
  }
}

export const sbpService = new SbpService(fetch, API, cache, config);
