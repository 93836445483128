import { mergeThemeVariables } from '@brainysoft/lk-custom-kernel/utils/merge-theme-variables';
import { variables } from './theme/Styles';

export const VARIABLES = mergeThemeVariables(variables);

/** @deprecated */ export const logo = VARIABLES.logo;
/** @deprecated */ export const logoInverted = VARIABLES.logoInverted;
/** @deprecated */ export const logoRegistration = VARIABLES.logoRegistration;
/** @deprecated */ export const logoAuth = VARIABLES.logoAuth;
/** @deprecated */ export const logoFooter = VARIABLES.logoFooter;
/** @deprecated */ export const authBackground = VARIABLES.authBackground;
/** @deprecated */ export const maxWidth = VARIABLES.maxWidth;
/** @deprecated */ export const fontFamily = VARIABLES.fontFamily;
/** @deprecated */ export const topBarHeight = VARIABLES.topBarHeight;
/** @deprecated */ export const topBarHeightMobile = VARIABLES.topBarHeightMobile;
/** @deprecated */ export const generalBorder = VARIABLES.generalBorder;
/** @deprecated */ export const generalBorderLight = VARIABLES.generalBorderLight;
/** @deprecated */ export const generalBorderRadius = VARIABLES.generalBorderRadius;
/** @deprecated */ export const generalBorderRadiusLarge = VARIABLES.generalBorderRadiusLarge;
/** @deprecated */ export const generalTransition = VARIABLES.generalTransition;
/** @deprecated */ export const generalTableBorderRadius = VARIABLES.generalTableBorderRadius;
/** @deprecated */ export const loaderBarColor = VARIABLES.loaderBarColor;
/** @deprecated */ export const fontSizeBase = VARIABLES.fontSizeBase;
/** @deprecated */ export const fontSizeLarge = VARIABLES.fontSizeLarge;
/** @deprecated */ export const fontSizeSmall = VARIABLES.fontSizeSmall;
/** @deprecated */ export const inputBorderColor = VARIABLES.inputBorderColor;
