import { THEME_COLORS } from '@brainysoft/lk-custom/colors';

const { grey } = THEME_COLORS;

export const helpers = {
  '.flex-start': {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  '.flex-justify': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '.flex-end': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  '.flex-center': {
    display: 'flex',
    justifyContent: 'center',
  },
  '.mb0': {
    marginBottom: 0,
  },
  '.mb1': {
    marginBottom: '1rem',
  },
  '.mb2': {
    marginBottom: '2rem',
  },
  '.mt0': {
    marginTop: 0,
  },
  '.mt1': {
    marginTop: '1rem',
  },
  '.mt2': {
    marginTop: '2rem',
  },
  '.ml0': {
    marginLeft: 0,
  },
  '.ml1': {
    marginLeft: '1rem',
  },
  '.ml2': {
    marginLeft: '2rem',
  },
  '.mr0': {
    marginRight: 0,
  },
  '.mr1': {
    marginRight: '1rem',
  },
  '.mr2': {
    marginRight: '2rem',
  },
  '.pb0': {
    paddingBottom: 0,
  },
  '.pb1': {
    paddingBottom: '1rem',
  },
  '.pb2': {
    paddingBottom: '2rem',
  },
  '.pt0': {
    paddingTop: 0,
  },
  '.pt1': {
    paddingTop: '1rem',
  },
  '.pt2': {
    paddingTop: '2rem',
  },
  '.pl0': {
    paddingLeft: 0,
  },
  '.pl1': {
    paddingLeft: '1rem',
  },
  '.pl2': {
    paddingLeft: '2rem',
  },
  '.pr0': {
    paddingRight: 0,
  },
  '.pr1': {
    paddingRight: '1rem',
  },
  '.pr2': {
    paddingRight: '2rem',
  },
  '.text': {
    '&-center': {
      textAlign: 'center',
    },
    '&-small': {
      fontSize: '.875em',
    },
    '&-large': {
      fontSize: '1.25em',
    },
    '&-grey': {
      color: `${grey} !important`,
    },
  },
  '.nowrap': {
    whiteSpace: 'nowrap',
  },
};
