const Color = require('color');

module.exports = {
  white: 'rgb(255,255,255)',
  black: 'rgb(0,0,0)',
  primary: 'rgb(33,167,63)',
  primaryDarken: Color('rgb(33,167,63)').darken(0.3).rgb().string(),
  primaryLight: Color('rgb(33,167,63)').lighten(0.3).rgb().string(),
  primaryDark: Color('rgb(33,167,63)').darken(0.7).rgb().string(),
  grey: 'rgb(179,180,181)',
  greyDark: 'rgb(119,120,121)',
  greyLight: 'rgb(219,220,221)',
  greyLighter: Color('rgb(179,180,181)').lighten(0.3).rgb().string(),
  greyText: 'rgb(59,60,61)',
  greyLightText: 'rgb(179,180,181)',
  red: 'rgb(240,20,20)',
  blue: 'rgb(0,160,220)',
  green: 'rgb(0,190,40)',
  yellow: 'rgb(250,150,0)',
  pageBackground: 'rgb(242,243,244)',
  primaryHover: 'rgb(248,252,255)',
  primaryBackground: 'rgb(245,250,255)',

  secondary: 'rgb(229,9,127)',
  secondaryBackground: 'rgb(255,245,250)',

  highlightColor: 'rgb(245,20,20)',
  disabledBackground: 'rgb(250,250,250)',
};
