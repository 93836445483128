import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';

interface IGetCardTokenData {
  backLink: string;
}

interface IInitRepaymentLinkData {
  contractId: number;
  return_url: string;
  amount: number;
  options?: any;
  useCardToken: boolean;
}

interface ICalcCommissionData {
  amount: number;
}

interface IInsuranceAmountData {
  amount?: number;
  leadId?: number;
  loanApplicationId?: number;
  contractId?: number;
}

class PaymentsService extends Entity {
  public async initCard(data: IGetCardTokenData) {
    this.storeEntity = 'payments';
    const result = await this.post(data, '/card/init');
    return result;
  }

  public async getCard() {
    this.storeEntity = 'payments';
    const result = await this.fetch({}, `/card`);
    return result;
  }

  public async initRepaymentLink(data: IInitRepaymentLinkData) {
    this.storeEntity = 'payments';
    const result = await this.post(data, '/initRepaymentLink');
    return result;
  }

  public async calcCommission(data: ICalcCommissionData) {
    this.storeEntity = 'payments';
    const result = await this.post(data, '/calcCommission');
    return result;
  }

  public async getInsuranceAmount(data: IInsuranceAmountData): Promise<{ insuranceAmount: number }> {
    this.storeEntity = 'payments';
    const result = await this.post(data, '/getInsuranceAmount');
    return result;
  }
  //
  // public async getCardTokenStatus(statusId: string): Promise<{status: number, error: string}> {
  //     this.storeEntity = 'lanister';
  //     const result = await this.fetch('', `/card-token-status/${statusId}`);
  //     return result;
  // }
  //
  public async getTransactionStatus(statusId: string): Promise<{ status: number; error: string }> {
    this.storeEntity = 'payments';
    const result = await this.fetch('', `/transaction-status/${statusId}`);
    return result;
  }
  //
  // public async cardTokenStatus(uuid:string, statusId: string): Promise<{status: string, error: string}> {
  //     this.storeEntity = 'process';
  //     const result = await this.fetch('', `/loanApp/${uuid}/card-binding-status/${statusId}`);
  //     return result;
  // }

  public async cardTokenStatus(statusId: string): Promise<{ status: string; error: string }> {
    this.storeEntity = 'payments';
    const result = await this.fetch('', `/card/status/${statusId}`);
    return result;
  }
}

export const paymentsService = new PaymentsService(fetch, API, cache, config);
