import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { generalBorderRadius, generalTransition } from '@brainysoft/lk-custom/variables';
import { greyLight, primary, secondary, white } from '@brainysoft/lk-custom/colors';
import Color from 'color';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  creditProductsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '1rem',
    justifyContent: 'center',
    '& .ant-row': {
      width: '100%',
    },
    '& .ant-col': {
      display: 'flex',
      alignContent: 'stretch',
    },
  },
  creditProduct: {
    cursor: 'pointer',
    borderRadius: generalBorderRadius,
    border: `1px solid ${greyLight}`,
    position: 'relative',
    backgroundColor: Color(primary).mix(Color(white), 1).rgb().string(),
    transition: generalTransition,
    padding: '1rem',
    marginBottom: '1rem',
    flex: 1,
    '&:hover': {
      backgroundColor: Color(secondary).mix(Color(white), 0.95).rgb().string(),
      boxShadow: '0 0 20px rgba(0,0,0,0.05)',
      border: `1px solid ${secondary}`,
      '& .addCardPlaceholder': {
        backgroundColor: primary,
        color: primary,
      },
    },
    '&.selected': {
      backgroundColor: Color(primary).mix(Color(white), 0.95).rgb().string(),
      border: `1px solid ${primary}`,
      color: primary,
    },
    '&:not(:last-child)': {
      marginRight: '.5rem',
    },
    '&.titleOnly': {
      height: 150,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    '&.inWidget': {
      height: 'auto',
      flexBasis: '100%',
      marginBottom: '.5rem',
      marginRight: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  header: {
    textAlign: 'left',
    '&.inWidget': {
      textAlign: 'center',
      fontSize: '.875em',
      padding: '0 .5rem',
    },
  },
  name: {
    fontWeight: 'bold',
    marginBottom: '1rem',
    '&.titleOnly': {
      marginBottom: 0,
    },
    '&.inWidget': {
      fontSize: '.875rem',
      marginBottom: '.25rem',
    },
  },
  description: {
    fontSize: '.875rem',
    lineHeight: 1.3,
    '&.inWidget': {
      fontSize: '.8125rem',
      marginBottom: 0,
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    creditProduct: {
      '&.inWidget': {
        height: 'auto',
        flexBasis: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        '&:not(:last-child)': {
          marginRight: '.5rem',
        },
      },
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    creditProduct: {
      width: '100%',
      marginBottom: '.75rem',
      marginRight: '0 !important',
      // height: '50px',
      '&.inWidget': {
        height: 'auto',
        flexBasis: '100%',
        marginBottom: '.5rem',
        marginRight: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      },
    },
  },
};

export default css;
