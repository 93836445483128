import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { topBarHeight } from '../../../variables';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  headerWrap: {
    height: topBarHeight,
    width: '100%',
    margin: '0 auto',
    maxWidth: 'auto',
    padding: '1rem 1.5rem',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 1,
  },
  // phoneWrap: {
  //     flex: 1,
  //     textAlign: 'right',
  //     marginLeft: '1.875rem',
  //     display: 'flex',
  //     flexDirection: 'column',
  //     justifyContent: 'center',
  //     alignItems: 'flex-end',
  // },
  // phoneBlockWrapper: {
  //     textAlign: 'right'
  // },
  // phoneNumber: {
  //     fontSize: '1.5rem',
  //     fontWeight: 'bold',
  //     color: '#4d4d4c',
  //     marginLeft: '.75rem',
  //     textDecoration: 'none',
  //     display: 'inline-block'
  // },
  // phoneDetail: {
  //     marginTop: '.25rem',
  //     display: 'block',
  //     fontSize: '0.8rem',
  //     color: '#6e6e6e',
  // },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    headerWrap: {
      padding: '0.9375rem',
      position: 'initial',
    },
    // phoneNumber: {
    //     fontSize: '0.875rem',
    // },
    // phoneDetail: {
    //     fontSize: '0.625rem',
    // },
    // phoneWrap: {
    //     display: 'none',
    // },
  },
};

export default css;
