import { SCREEN_SIZE } from '@brainysoft/lk-components';
import {
  fontFamily,
  generalBorder,
  generalTableBorderRadius,
  generalTransition,
} from '@brainysoft/lk-custom/variables';
import { greyText, primaryHover } from '@brainysoft/lk-custom/colors';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  table: {
    width: '100%',
    borderSpacing: '0 5px',
    marginBottom: '1.5rem',
    fontFamily: fontFamily,
  },
  mobileonly: {
    display: 'none!important',
  },
  tableHeader: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    color: greyText,
    '& th': {
      fontWeight: 'normal',
    },
  },
  tableBody: {
    '& tr': {
      border: generalBorder,
      borderRadius: '0.375rem',
      marginBottom: '0.3125rem',
    },
    '& tr.clickable': {
      '& td': {
        transition: generalTransition,
        cursor: 'pointer',
      },
      '&:hover td': {
        background: primaryHover,
      },
    },
    '& td': {
      padding: ['1rem', '0.875rem'],
      borderTop: generalBorder,
      borderBottom: generalBorder,
      textAlign: 'center',
    },
    '& td:nth-child(1)': {
      borderLeft: generalBorder,
      borderTopLeftRadius: generalTableBorderRadius,
      borderBottomLeftRadius: generalTableBorderRadius,
    },
  },
  lastCell: {
    borderRight: generalBorder,
    borderTopRightRadius: generalTableBorderRadius,
    borderBottomRightRadius: generalTableBorderRadius,
  },
  currencyCell: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    '& i': {
      fontStyle: 'normal',
    },
  },
  typeCell: {
    fontSize: '0.875rem',
  },
  numberCell: {
    textDecoration: 'underline',
    fontSize: '0.875rem',
    transition: '0.3s all ease',
  },
  summCell: {
    fontWeight: 'bold',
    fontSize: '1.375rem',
    '& i': {
      fontStyle: 'normal',
      fontSize: '1.0625rem',
      fontFamily: fontFamily,
    },
  },
  cell: {
    fontSize: '0.875rem',
  },
  dateCell: {
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
  },
  balanceCell: {
    fontSize: '1.375rem',
    fontWeight: 'bold',
    '& i': {
      fontStyle: 'normal',
      fontSize: '1.0625rem',
      fontFamily: fontFamily,
    },
    '&$red': {
      color: '#e60000',
    },
  },
  currentStatusCell: {},
  currentStatus: {
    display: 'inline-block',
    lineHeight: 1.2,
    fontSize: '.75rem',
  },
  entityType: {
    textAlign: 'left !important',
    fontSize: '0.875rem',
  },
  red: {
    fontStyle: 'normal',
  },
  cellRight: {
    textAlign: 'right !important',
  },
  wideCell: {
    width: '30%',
  },
  noData: {
    '& .anticon': {
      marginRight: '.5rem',
    },
  },
  cellLeft: {
    textAlign: 'left !important',
  },
  mobileHeaderItem: {},
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    currentStatusCell: {
      width: 200,
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    headerWrapper: {
      display: 'block',
    },
    tableHeader: {
      display: 'none',
    },
    mobileonly: {
      display: 'inline-block!important',
    },
    tableBody: {
      '& tr': {
        display: 'flex',
        flexWrap: 'wrap',
        padding: ['0.25rem', '0.75rem'],
        position: 'relative',
      },
      '& td': {
        border: 'none',
        width: '100%',
        textAlign: 'left',
        padding: ['0.5rem', 0],
      },
      '& td:nth-child(1)': {
        borderLeft: 'none',
        borderRadius: 0,
      },
      '& td:nth-child(6)': {
        borderRight: 'none',
        borderRadius: 0,
        padding: 0,
      },
    },
    mobileHeaderItem: {
      fontSize: '0.75rem',
      color: '#969696',
      background: 'transparent',
      fontWeight: 'normal',
      width: '35%',
    },
  },
};

export default css;
