import { ThemeVariablesInterface } from './theme-variables.interface';
import { merge } from 'lodash';
import { variables as defaultVariables } from '@custom/defaults/theme/Styles';

export const mergeThemeVariables = (custom: { [k: string]: unknown }): ThemeVariablesInterface => {
  const variables = merge(defaultVariables, custom);
  return {
    logo: variables.logo,
    logoInverted: variables.logoInverted,
    logoRegistration: variables.logoRegistration,
    logoAuth: variables.logoAuth,
    logoFooter: variables.logoFooter,
    authBackground: variables.authBackground,
    maxWidth: variables.maxWidth,
    fontFamily: variables.fontFamily,
    topBarHeight: variables.topBarHeight,
    topBarHeightMobile: variables.topBarHeightMobile,
    generalBorder: variables.generalBorder,
    generalBorderLight: variables.generalBorderLight,
    generalBorderRadius: variables.generalBorderRadius,
    generalBorderRadiusLarge: variables.generalBorderRadiusLarge,
    generalTransition: variables.generalTransition,
    generalTableBorderRadius: variables.generalTableBorderRadius,
    loaderBarColor: variables.loaderBarColor,
    fontSizeBase: variables.fontSizeBase,
    fontSizeLarge: variables.fontSizeLarge,
    fontSizeSmall: variables.fontSizeSmall,
    inputBorderColor: variables.inputBorderColor,
  };
};
