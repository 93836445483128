import * as React from 'react';
import { refreshJivo, useJivo } from '../queries/useJivo';
import { useQueryClient } from 'react-query';
import { useRouter } from 'next/router';

interface IJivoWidget {
  jivoName: string;
  jivoLink: string;
  jivoContainer: string;
}

interface IJivo extends IJivoWidget {
  urlsRegException?: RegExp[];
}

export const Jivo: React.FC<IJivo> = (props) => {
  const { jivoName = '', jivoLink = '', jivoContainer = '', urlsRegException = [] } = props;
  const { asPath } = useRouter();

  for (const regUrl of urlsRegException) {
    if (new RegExp(regUrl, 'i').test(asPath)) return null;
  }

  return <JivoWidget jivoName={jivoName} jivoLink={jivoLink} jivoContainer={jivoContainer} />;
};

const JivoWidget: React.FC<IJivoWidget> = (props) => {
  const { jivoName, jivoLink, jivoContainer } = props;

  const jivoQuery = useJivo();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    refreshJivo(queryClient);
    return () => {
      removeJivo();
    };
  }, []);

  React.useEffect(() => {
    if (jivoQuery.isFetched) {
      const isJivo = jivoQuery.data?.isJivo ?? false;
      if (isJivo) addJivo();
      else removeJivo();
    }

    return () => {
      if (!jivoQuery.data?.isJivo) removeJivo();
    };
  }, [jivoQuery.isFetched]);

  const addJivo = () => {
    const element = findJivo();

    if (!element) {
      const iframe = document.createElement('iframe');
      const script = document.createElement('script');

      iframe.id = `${jivoContainer}:${jivoName}`;
      iframe.className = `${jivoContainer}:${jivoName}`;

      script.type = 'text/javascript';
      script.async = true;
      script.src = `${jivoLink}/assets/js/${jivoName}`;
      iframe.addEventListener('load', () => {
        iframe.contentDocument.head.appendChild(script);
      });
      document.head.appendChild(iframe);
      const css = document.createElement('link');
      css.setAttribute('rel', 'stylesheet');
      css.setAttribute('type', 'text/css');
      css.setAttribute('href', `${jivoLink}/assets/css/${jivoName}`);
      document.getElementsByTagName('head')[0].appendChild(css);
    }
  };

  const removeJivo = () => {
    const element = findJivo();
    if (element) {
      element.remove();
    }
    const widgetElements = document.querySelectorAll('body > *[class^="webchat"]');
    widgetElements.forEach((e) => e.remove());
  };

  const findJivo = () => {
    return document.getElementById(`${jivoContainer}:${jivoName}`);
  };

  return <></>;
};
