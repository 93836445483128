import { useQuery } from 'react-query';
// eslint-disable-next-line no-unused-vars
import { UseQueryResult } from 'react-query/types/react/types';
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import { QueryClient } from 'react-query/core';
import { ONE_HOUR_STALE } from '../const';
// eslint-disable-next-line no-unused-vars
import { consumerService, LegalEntityInfoT } from '@brainysoft/lk-components';

export const useLegalEntityInfo: (options?: Record<string, unknown>) => UseQueryResult<LegalEntityInfoT> = (
  options = { staleTime: ONE_HOUR_STALE }
) => {
  return useQuery(
    ['legalEntityInfo'],
    () => {
      return consumerService.getLegalEntityInfo() as Promise<LegalEntityInfoT>;
    },
    options
  );
};

export const refreshLegalEntityInfo: (queryClient: QueryClient) => void = (queryClient) => {
  queryClient.invalidateQueries(['legalEntityInfo']);
};
