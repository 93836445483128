import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';

class AddonsService extends Entity {
  public async getMeanIncome(regionId) {
    this.storeEntity = 'addons';
    const result: { meanIncome: number | null } = await this.fetch('', `/${regionId}/get-mean-income`);
    return result;
  }
}

export const addonsService = new AddonsService(fetch, API, cache, config);
