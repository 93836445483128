import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { generalBorder, generalBorderRadiusLarge, generalTransition } from '@brainysoft/lk-custom/variables';
import Color from 'color';

const { pageBackground } = THEME_COLORS;

export const css = {
  creditInfoDetails: {
    fontSize: '1.25rem',
  },
  individualOfferSection: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
    width: '100%',
    '& .individual-offer-block': {
      display: 'flex',
      width: '100%',
      background: '#ffffff',
      border: '2px solid #CFD3D9',
      borderRadius: '8px',
      marginBottom: '1rem',
      padding: '35px 40px 45px 40px',
      flexWrap: 'wrap',
      transition: generalTransition,
      cursor: 'pointer',
      '&.unactive': {
        borderColor: '#e0e2e8',
        '& .individual-offer-value': {
          color: '#9d9faa',
        },
      },
      '&:hover,&.active': {
        borderColor: '#2B7CB6',
        boxShadow: '0 0 0 2px #2B7CB6',
      },
      '&.disabled': {
        cursor: 'default',
        border: '2px solid #ebebeb !important',
        color: 'red',
        '& .individual-offer-value': {
          color: '#ebebeb !important',
        },
        '& .individual-offer-label': {
          color: '#ebebeb',
        },
      },
    },
    '& .individual-offer-amount': {
      width: '25%',
    },
    '& .individual-offer-term': {
      width: '25%',
    },
    '& .individual-offer-addition': {
      width: '50%',
      display: 'flex',
      alignItems: 'center',
      '& .individual-offer-value': {
        fontSize: '16px',
        color: '#46495B',
      },
    },
    '& .individual-offer-label': {
      color: '#82859B',
      fontSize: '14px',
      textAlign: 'left',
    },
    '& .individual-offer-value': {
      textAlign: 'left',
    },
    '& .individual-offer-value_bold': {
      fontSize: '24px',
      fontWeight: 'bold',
    },
  },
  individualOfferBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
  },
  individualOfferItemBlock: {
    display: 'flex',
    color: '#aeaeae',
    gap: 12,
    '& .creditInfoRadioBtn': {
      width: '22px',
      height: '22px',
      borderRadius: '50%',
      background: pageBackground,
      border: `2px solid ${pageBackground}`,
      outline: '2px solid blue',
      padding: '5px',
      cursor: 'pointer',
    },
    '&.active': {
      color: '#000000',
      '& .creditInfoRadioBtn': {
        background: 'blue',
        cursor: 'inherit',
      },
    },
  },
  creditInfoRadioBtnBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  creditInfoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1rem',
    textAlign: 'left',
    marginBottom: '.375rem',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  creditInfoAmountWrapper: {
    paddingLeft: '.5rem',
    fontSize: '1.125rem',
    fontWeight: 'bold',
    textAlign: 'right',
  },
  creditInfoPeriodWrapper: {
    fontSize: '0.9125rem',
  },
  creditInfoPeriod: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
  },
  creditInfo: {
    maxWidth: 400,
    padding: '1.5rem',
    margin: '0 1.5rem 1rem',
    textAlign: 'center',
    border: generalBorder,
    borderRadius: generalBorderRadiusLarge,
    width: '100%',
    background: Color(pageBackground).lighten(0.035).rgb().string(),
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    activeCreditPageWrapper: {
      fontSize: '.875rem',
    },
    creditInfo: {
      padding: '1.5rem',
      margin: '0 0 1rem',
      fontSize: '.875rem',
    },
    creditInfoRow: {
      fontSize: '.875rem',
      alignItems: 'center',
      marginBottom: '.25rem',
    },
    creditInfoAmountWrapper: {
      fontSize: '1.25rem',
    },
    individualOfferSection: {
      width: '120%',
      marginLeft: '-1.5rem',
      marginRight: '-1.5rem',
      '& .individual-offer-amount': {
        width: '50%',
      },
      '& .individual-offer-term': {
        width: '50%',
      },
      '& .individual-offer-addition': {
        width: '100%',
      },
      '& .individual-offer-block': {
        padding: '24px 30px 24px 30px',
      },
    },
  },
};

export default css;
