import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { disabledBackground, greyText, THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { VARIABLES } from '@brainysoft/lk-custom/variables';

const { red } = THEME_COLORS;
const { generalBorderRadius } = VARIABLES;

export const css = {
  error: {
    color: red,
    fontSize: '0.75rem',
    lineHeight: 1.2,
  },
};

export default css;
