var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3.10.8"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import {
  NODE_ENV_PRODUCTION,
  TENANT_DOMAIN,
  SENTRY_DSN as _SENTRY_DSN,
  SENTRY_TRACE_SAMPLE_RATE as _SENTRY_TRACE_SAMPLE_RATE,
} from './src/config';

const SENTRY_DSN = _SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_TRACE_SAMPLE_RATE =
  parseFloat(_SENTRY_TRACE_SAMPLE_RATE || process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE) || 0.1;
const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT
  ? process.env.NEXT_PUBLIC_ENVIRONMENT
  : NODE_ENV_PRODUCTION
  ? 'production'
  : 'local';

Sentry.init({
  dsn: SENTRY_DSN,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

console.log(`Sentry DSN ${SENTRY_DSN ? 'loaded' : 'unknown'}`);
console.log('Sentry trace sample rate', SENTRY_TRACE_SAMPLE_RATE);
console.log('Sentry environment', ENVIRONMENT);
Sentry.setTag('customer_key', TENANT_DOMAIN);
Sentry.setTag('environment', ENVIRONMENT);
