import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { green, greyLightText, red, THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { generalBorder, generalBorderRadius, generalBorderRadiusLarge } from '@brainysoft/lk-custom/variables';
import Color from 'color';

// const {generalBorder, generalBorderRadiusLarge} = VARIABLES;
const { greyText, pageBackground, white } = THEME_COLORS;

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  activeCreditPageWrapper: {
    fontSize: '1rem',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    padding: '0 1.5rem',
    background: white,
    '& .checkmark': {
      margin: '1rem 0',
    },
    '&.inWidget': {
      padding: '0 1rem',
      '& [class*="header"]': {
        fontSize: '1rem',
      },
      '& [class*="descriptor"]': {
        fontSize: '.875rem',
      },
      '& .checkmark': {
        margin: '.75rem 0',
      },
    },
    '&.uploadPhoto': {
      textAlign: 'left',
      alignItems: 'flex-start',
    },
  },
  loadingWrapper: {
    margin: '2rem 0',
  },
  header: {
    lineHeight: 1.2,
    fontSize: '1.25rem',
    marginTop: 0,
    marginBottom: '2rem',
  },
  descriptor: {
    marginBottom: 0,
  },
  creditInfo: {
    maxWidth: 400,
    padding: '1.5rem',
    margin: '1.5rem',
    textAlign: 'center',
    border: generalBorder,
    borderRadius: generalBorderRadiusLarge,
    width: '100%',
    background: Color(pageBackground).lighten(0.035).rgb().string(),
  },
  creditInfoHeader: {
    marginTop: 0,
    marginBottom: '1rem',
  },
  creditInfoDetails: {
    fontSize: '1.25rem',
  },
  creditInfoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1rem',
    textAlign: 'left',
  },
  creditInfoAmountWrapper: {
    paddingLeft: '.5rem',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '.5rem',
    textAlign: 'right',
  },
  creditInfoPeriodWrapper: {
    fontSize: '0.9125rem',
  },
  creditInfoPeriod: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
  },
  activeCreditButtonWrapper: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    '& .ant-btn:not(:last-child)': {
      marginRight: '.75rem',
    },
  },
  activeCreditPlaceholderButtonWrapper: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
  },
  activeCreditPlaceholderTitle: {
    fontSize: '.875rem',
    textAlign: 'center',
    color: greyText,
    marginBottom: '1rem',
  },
  uploadPassportWrapper: {
    textAlign: 'left',
  },
  uploadSignWrapper: {
    textAlign: 'left',
  },
  passportImgWrapper: {
    position: 'relative',
    padding: '0 1rem',
    '& img': {
      width: '100%',
    },
  },
  statusIcon: {
    position: 'absolute',
    top: '0',
    right: '1rem',
    transform: 'translate(50%,-40%)',
    borderRadius: '500px',
  },
  interactionSubmitWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  homeButton: {
    marginTop: '1rem',
  },
  photoInfo: {
    fontSize: '.875em',
    lineHeight: 1.2,
    '& h3': {
      marginTop: 0,
    },
  },
  registrationFormPassport: {
    '& > .ant-col': {
      marginBottom: '1rem',
    },
  },
  phoneMessage: {
    fontSize: '.875rem',
    lineHeight: 1.3,
    '& .ant-btn': {
      marginTop: '1rem',
      fontSize: '.875rem',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  insuranceWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: generalBorder,
    borderRadius: generalBorderRadius,
    padding: '1rem',
    '& .ant-form-item': {
      marginBottom: '0 !important',
    },
    '& .ant-btn': {
      padding: '0 !important',
    },
    '& .insuranceDocument': {
      display: 'flex',
      marginTop: '.5rem',
      marginBottom: '.5rem',
      '& [class*="documentLink"]': {
        fontSize: '.875rem',
        padding: 0,
      },
    },
  },
  insuranceContent: {
    fontSize: '.875rem',
  },
  insuranceHeader: {
    marginBottom: '.125rem',
  },
  insurancePrintFormWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '.125rem',
    '& > :first-child': {
      marginRight: '.5rem',
    },
  },
  insuranceButton: {
    color: `${greyLightText} !important`,
    height: 'auto',
    cursor: 'text',
  },
  insuranceAmount: {
    fontSize: '.875rem',
    marginBottom: '1.5rem',
  },
  insuranceInfoButton: {
    cursor: 'pointer',
  },
  insuranceIcon: {
    fontSize: '4rem',
    lineHeight: 1,
    marginLeft: '1rem',
    color: red,
    '&.accepted': {
      color: green,
    },
    '&.small': {
      fontSize: '2rem',
    },
  },
  modalHeader: {
    marginBottom: '1rem',
    lineHeight: 1.4,
  },
  modalContent: {
    paddingLeft: 0,
    '& > li': {
      marginLeft: '1rem',
      marginBottom: '.25rem',
      lineHeight: 1.4,
    },
  },
  cancelModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cancelModalHeader: {
    padding: '0 2rem',
    lineHeight: 1.3,
    textAlign: 'center',
  },
  cancelActionsWrapper: {
    display: 'flex',
    '& > :not(:last-child)': {
      marginRight: '1rem',
    },
  },
  deniedHeader: {
    lineHeight: 1.2,
    fontSize: '1.125rem',
    marginTop: 0,
  },
  deniedDescription: {},
  moneyMethodWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  cardsWrapper: {
    width: '90%',
    margin: '0 auto 1rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    '& > [class^="card"]': {
      margin: '0 .5rem 1rem',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    activeCreditPageWrapper: {
      fontSize: '.875rem',
    },
    creditInfo: {
      padding: '1.5rem',
      margin: '1.5rem 0',
      fontSize: '.875rem',
    },
    creditInfoRow: {
      fontSize: '.875rem',
      alignItems: 'center',
      marginBottom: '.25rem',
    },
    creditInfoAmountWrapper: {
      fontSize: '1.25rem',
    },
  },
};

export default css;
