import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { greyLight, greyLightText, primary, primaryHover } from '@brainysoft/lk-custom/colors';
import { generalBorderRadiusLarge, generalTransition } from '@brainysoft/lk-custom/variables';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  documents: {
    position: 'relative',
  },
  document: {
    border: `1px solid ${greyLight}`,
    borderRadius: generalBorderRadiusLarge,
    padding: '1.25rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '.5rem',
    transition: generalTransition,
    '&>span>.anticon': {
      marginRight: '1rem',
    },
    '&:hover': {
      color: primary,
      borderColor: primary,
      background: primaryHover,
    },
  },
  icon: {
    fontSize: '1.5em',
  },
  downloadIcon: {
    cursor: 'pointer',
  },
  documentName: {
    paddingRight: '.5rem',
  },
  contract: {
    padding: '0 .5rem 0 0',
  },
  documentContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '1rem',
  },
  downloadWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  weight: {
    fontSize: '.75em',
    opacity: 0.7,
    marginRight: '1rem',
  },
  noData: {
    border: `1px solid ${greyLight}`,
    borderRadius: generalBorderRadiusLarge,
    display: 'flex',
    justifyContent: 'center',
    color: greyLightText,
    padding: '1rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    document: {
      fontSize: '1rem',
    },
    weight: {
      display: 'block',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    document: {
      fontSize: '.875rem',
    },
    weight: {
      display: 'none',
    },
  },
};

export default css;
