import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';

class LeadService extends Entity {
  public async getIndividualOffers(leadId, loanApplicationId) {
    this.storeEntity = 'lead';
    const result = await this.fetch('', `/${leadId}/${loanApplicationId}/individual-offers`);
    return result;
  }
}

export const leadService = new LeadService(fetch, API, cache, config);
