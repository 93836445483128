import { THEME_COLORS } from '@brainysoft/lk-custom/colors';

const { disabledBackground, greyText, white } = THEME_COLORS;

export const antdPatches = {
  '.ant-calendar-input-wrap': {
    height: 'auto !important',
    padding: '1rem 1.25rem !important',
  },
  '.ant-dropdown': {
    background: white,
    borderRadius: 4,
  },
  '.credit-table-actions': {
    '.ant-popover-inner': {
      boxShadow: '0 2px 16px rgba(0, 0, 0, 0.20)',
    },
    '.ant-popover-inner-content': {
      padding: 0,
    },
  },
  '.table-filters': {
    '& .ant-dropdown-menu-item': {
      fontSize: '.8125rem !important',
    },
  },
  '.button-group': {
    '& .ant-btn:not(:last-child)': {
      marginRight: '.25rem',
    },
  },
  '.ant-input[disabled]': {
    color: greyText,
    backgroundColor: disabledBackground,
  },
  '.ant-avatar': {},
};
