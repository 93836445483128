import { SCREEN_SIZE } from '@brainysoft/lk-components';
// import { generalBorderRadiusLarge, generalTransition } from '../../styles/variables';
// import { grey, greyDark, greyLight, greyText, primary } from '../../styles/colors';

export const css = {
  tag: {
    lineHeight: 1.2,
    paddingTop: '.25rem',
    paddingBottom: '.25rem',
    '&.multiline': {
      whiteSpace: 'normal',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
