import { NProgress } from '@tanem/react-nprogress';
import * as React from 'react';
import { loaderBarColor } from '@brainysoft/lk-custom/variables';

interface IProps {
  isRouteChanging: boolean;
  loadingKey: string;
  color?: string;
}

const Loading: React.FC<IProps> = (props) => {
  const color = props.color ? props.color : loaderBarColor;
  return (
    <NProgress isAnimating={props.isRouteChanging} key={props.loadingKey}>
      {({ isFinished, progress, animationDuration }) => (
        <div
          style={{
            opacity: isFinished ? 0 : 1,
            pointerEvents: 'none',
            transition: `opacity ${animationDuration}ms linear`,
          }}
        >
          <div
            style={{
              background: color,
              height: 2,
              left: 0,
              marginLeft: `${(-1 + progress) * 100}%`,
              position: 'fixed',
              top: 0,
              transition: `margin-left ${animationDuration}ms linear`,
              width: '100%',
              zIndex: 9999,
            }}
          >
            <div
              style={{
                boxShadow: `0 0 10px ${color}, 0 0 5px ${color}`,
                display: 'block',
                height: '100%',
                opacity: 1,
                position: 'absolute',
                right: 0,
                transform: 'rotate(3deg) translate(0px, -4px)',
                width: 100,
              }}
            />
          </div>
        </div>
      )}
    </NProgress>
  );
};

export default Loading;
