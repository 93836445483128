import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { VARIABLES } from '@brainysoft/lk-custom/variables';
import { SCREEN_SIZE } from '@brainysoft/lk-components';

const { generalBorderRadius, generalTransition } = VARIABLES;
const { red } = THEME_COLORS;

export const css = {
  label: {
    fontSize: '.875rem',
    lineHeight: 1,
    marginBottom: '.25rem',
  },
  upload: {
    borderRadius: generalBorderRadius,
    fontSize: '1rem',
    padding: 0,
    width: '100%',
    '& .ant-upload': {
      lineHeight: 1,
    },
    '& .ant-upload.ant-upload-select-picture-card': {
      width: '100%',
      height: 300,
    },
    '& .ant-upload.ant-upload-select-picture-card > .ant-upload': {
      padding: '.5rem',
    },
    '&.error': {
      borderColor: red,
    },
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    '& .delete-image-button': {
      position: 'absolute',
      top: 5,
      right: 5,
      opacity: 0.3,
      transition: generalTransition,
    },
    '&:hover': {
      '& .delete-image-button': {
        opacity: 1,
      },
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    upload: {},
  },
};

export default css;
