import { notify } from '../../utils';
import { keyExistsHelper, trans } from '../../../../../apps/lk-next/src/utils/trans';

export const displayError = (errors: any[] | any, url = '', headers?: Record<string, unknown>) => {
  const err = Array.isArray(errors) ? errors : [errors];
  const method = headers?.method ?? 'GET';

  err.forEach((error) => {
    if (error === 'API_ERROR_MIDDLEWARE') {
      //errors have already been shown in middleware, skip
      return;
    }
    if (error?.activityLabel) {
      //remote error
      let message;
      if (error?.message) {
        console.log(`${method} ${url} error ==> `, error?.message);
        switch (error?.title) {
          case 'ERROR_DATA_FORACTION':
            message = error?.details?.message ?? error?.message;
            break;
          case 'ERROR_API_REQUEST':
            message = error?.details?.message ?? error?.message;
            break;
          default:
            message = keyExistsHelper(`errors:${error?.message}`)
              ? trans('errors', error?.message)
              : trans('errors', 'ERROR__UNKNOWN');
        }
      } else {
        console.log(`${method} ${url} error ==> `, error?.title);
        switch (error?.title) {
          case 'ERROR_API_REQUEST':
            message = error?.details?.message ?? error?.message;
            break;
          default:
            message = keyExistsHelper(`errors:${error?.title}`)
              ? trans('errors', error?.title)
              : trans('errors', 'ERROR__UNKNOWN');
        }
      }

      console.log(`Activity label ==> ${error?.activityLabel}`);
      notify.error(message, error?.activityLabel);
    } else {
      console.log(`${method} ${url} error ==> `, error);
      notify.error(
        error?.message && keyExistsHelper(`errors:${error?.message}`)
          ? trans('errors', error?.message)
          : trans('errors', 'ERROR__UNKNOWN')
      );
    }
  });
};
