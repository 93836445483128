import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { VARIABLES } from '@brainysoft/lk-custom/variables';
import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import Color from 'color';

const { generalTransition } = VARIABLES;
const { green, primary, secondary, white, red } = THEME_COLORS;

export const css = {
  button: {
    lineHeight: '1 !important',
    display: 'flex',
    alignItems: 'center',
    '& .ant-btn-loading-icon': {
      paddingRight: 8,
      '& .anticon': {
        paddingRight: 0,
      },
    },
    '&.ant-btn-sm': {
      fontSize: '.875em !important',
    },
    '&.ant-btn-round.ant-btn-lg': {
      padding: '2px 20px 4px',
      '& .anticon': {
        position: 'relative',
        top: 2,
      },
    },
    '&.ant-btn-primary:not([disabled])': {
      background: `linear-gradient(180deg, ${Color(primary)
        .darken(0.1)
        .rgb()
        .string()} 0%, ${primary} 100%) !important`,
      borderColor: 'transparent',
      position: 'relative',
      overflow: 'hidden',
      border: 0,
      '& > *': {
        position: 'relative',
        zIndex: 1,
      },
      '&:hover,&:active,&:focus': {
        '&:before': {
          opacity: 1,
          transition: generalTransition,
        },
      },
      '&:before': {
        content: '',
        position: 'absolute',
        display: 'block',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: `linear-gradient(120deg, ${primary} 20%, ${secondary} 100%)`,
        opacity: 0,
        transition: 'all 0.3s ease',
      },
    },
    '& [class^="spinnerContainer"]': {
      position: 'relative',
      top: 1,
      marginRight: '.75rem',
    },
    '&.green': {
      background: `${green} !important`,
      borderColor: 'transparent',
      position: 'relative',
      overflow: 'hidden',
      border: 0,
    },
    '&.red': {
      background: `${red} !important`,
      borderColor: 'transparent',
      position: 'relative',
      overflow: 'hidden',
      border: 0,
      color: white,
      '&:hover, &:active, &:focus': {
        background: `${Color(red).darken(0.1).rgb().toString()} !important`,
      },
    },
    '&.secondary': {
      background: `linear-gradient(180deg, ${Color(secondary)
        .darken(0.1)
        .rgb()
        .string()} 0%, ${secondary} 100%) !important`,
      color: `${white} !important`,
      borderColor: 'transparent',
      position: 'relative',
      overflow: 'hidden',
      border: 0,
    },
    '&.wide': {
      width: '100%',
      justifyContent: 'center',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    button: {
      fontSize: '0.875rem',
      padding: ['0.3125rem', '1.25rem'],
      '&.ant-btn-lg': {
        fontSize: '1rem',
      },
    },
  },
};

export default css;
