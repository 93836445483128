class Entity {
  private storeEntityValue = '';
  private processUuidValue: string | undefined;
  private api: any;

  constructor(fetch, API, cache, config, options = {}) {
    const { urlAPI } = config;
    this.api = new API(fetch, urlAPI, cache);
  }

  public set storeEntity(val: string) {
    this.storeEntityValue = val;
  }

  public set processUuid(uuid: string | undefined) {
    this.processUuidValue = uuid;
  }

  public async fetch(query = {}, subLink = '', ctx: any = null) {
    if (this.storeEntityValue) {
      const res = await this.api.get(
        this.storeEntityValue,
        query,
        subLink,
        ctx,
        this.processUuidValue
          ? {
              processUuid: this.processUuidValue,
            }
          : {}
      );
      return res;
    }
  }

  public async post(query = {}, subLink = '', multipart = false) {
    if (this.storeEntityValue) {
      const res = await this.api.post(
        this.storeEntityValue,
        query,
        subLink,
        multipart,
        this.processUuidValue
          ? {
              processUuid: this.processUuidValue,
            }
          : {}
      );
      return res;
    }
  }

  public async download(query = {}, subLink = '') {
    if (this.storeEntityValue) {
      const res = await this.api.download(this.storeEntityValue, query, subLink);
      return res;
    }
  }
}

export default Entity;
