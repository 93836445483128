import { VARIABLES } from '@brainysoft/lk-custom/variables';
import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { SCREEN_SIZE } from '@brainysoft/lk-components';
import Color from 'color';

const { fontSizeLarge, generalBorderRadius, generalTransition } = VARIABLES;
const { black, pageBackground, red, greyLightText, primary } = THEME_COLORS;

export const css = {
  label: {
    fontSize: '.875rem',
    lineHeight: 1,
    marginBottom: '.25rem',
  },
  upload: {
    borderRadius: generalBorderRadius,
    fontSize: fontSizeLarge,
    width: '100%',
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    background: `${Color(pageBackground).lighten(0.08).rgb().string()} !important`,
    '&.ant-upload': {
      lineHeight: '1 !important',
      '&:hover, &:active, &:focus': {
        borderColor: `${primary} !important`,
        '& .ant-upload-text .ant-btn': {
          borderColor: `${primary} !important`,
        },
        '& .anticon-plus': {
          color: `${primary} !important`,
        },
      },
    },
    '&.ant-upload.ant-upload-drag': {
      padding: '1rem !important',
    },
    '&.ant-upload.ant-upload-drag .ant-upload': {
      padding: '0 !important',
    },
    '& .ant-upload.ant-upload-select-picture-card': {
      width: '100%',
      height: 300,
    },
    '& .ant-upload.ant-upload-select-picture-card > .ant-upload': {
      padding: '.5rem',
    },
    '&.error': {
      borderColor: red,
    },
    '& .anticon.anticon-plus': {
      position: 'relative',
      fontSize: '1.125rem !important',
      verticalAlign: 'middle !important',
      color: black,
    },
    '& .ant-upload-text': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '0.9125em',
      lineHeight: 1,
      padding: '.5rem',
      minHeight: 90,
      color: greyLightText,
      '& .ant-btn': {
        marginBottom: '.75rem',
      },
    },
  },
  imagePlaceholder: {
    marginTop: '.75rem',
    marginBottom: '.75rem',
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    '&.compact': {
      marginTop: '.25rem',
      marginBottom: '.25rem',
    },
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: 0,
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    '& .delete-image-button': {
      position: 'absolute',
      top: 5,
      right: 5,
      opacity: 0.3,
      transition: generalTransition,
    },
    '&:hover': {
      '& .delete-image-button': {
        opacity: 1,
      },
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    upload: {},
    imagePlaceholder: {
      width: 'auto',
      height: 'auto',
      maxHeight: 400,
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    upload: {},
    imagePlaceholder: {
      width: 'auto',
      height: 'auto',
    },
  },
};

export default css;
