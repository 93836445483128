import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { black } from '@brainysoft/lk-custom/colors';

export const css = {
  pageTitle: {
    display: 'block',
    color: black,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    margin: [0, 0, '2rem'],
    textAlign: 'left',
  },
  skeleton: {
    '& .ant-skeleton-content .ant-skeleton-paragraph > li': {
      height: '1.25rem',
      marginBottom: '1.5rem',
    },
  },
  processWrapper: {
    margin: '0 auto',
    padding: '2rem 2rem 3rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&.registration': {
      padding: '1rem',
      width: '100%',
    },
  },
  processContent: {
    flex: '1 1 100%',
  },
  processActions: {
    marginTop: '2rem',
    flex: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  processSteps: {
    marginTop: '0 !important',
    marginBottom: '2rem !important',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    processWrapper: {
      padding: '2rem',
      margin: 0,
      width: '100%',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    header: {
      fontSize: '1.25rem',
    },
    processWrapper: {
      padding: '0',
    },
  },
};

export default css;
