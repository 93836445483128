export enum ClosedContractStatusType {
  /**
   * Погашен
   */
  REPAYED = 101481,

  /**
   * Реструктурированный
   */
  RESTRUCTURED = 101482,

  /**
   * Списан
   */
  WRITTEN_OFF = 101483,

  /**
   * Откорректирован
   */
  CORRECTED = 101484,

  /**
   * Списан по цессии
   */
  WRITTEN_OFF_CESSION = 101485,

  /**
   * Отменён
   */
  CANCELED = 101486,
}
