import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { greyLightText } from '@brainysoft/lk-custom/colors';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  disclaimerWrapper: {
    fontSize: '.875rem',
    lineHeight: 1.4,
    color: greyLightText,
    margin: '2rem 0 0',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    disclaimerWrapper: {
      fontSize: '.875rem',
      lineHeight: 1.4,
      color: greyLightText,
      margin: '1rem 0 0',
      textAlign: 'center',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
