import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';

class CounterService extends Entity {
  public async attempt(code) {
    this.storeEntity = 'counter';
    const result = await this.post({}, `/${code}/attempt`);
    return result;
  }

  public async isDelayed(code) {
    this.storeEntity = 'counter';
    const result = await this.fetch('', `/${code}/is_delayed`);
    return result;
  }
}

export const counterService = new CounterService(fetch, API, cache, config);
