import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';
import { IStatefulProcessService, StatefulProcessDataT } from '../../interfaces';
import { StatefulProcessActionsType, StatefulProcessType } from '../../enums';
// eslint-disable-next-line no-unused-vars

class StatefulProcessService extends Entity implements IStatefulProcessService {
  public async init(name: StatefulProcessType, data = {}): Promise<any> {
    this.storeEntity = 'statefulProcess';
    const result = await this.post(data, `/${name}/init`);
    return result;
  }

  public async getState(name: StatefulProcessType, uuid: string): Promise<StatefulProcessDataT> {
    this.storeEntity = 'statefulProcess';
    const result = await this.fetch('', `/${name}/${uuid}`);
    return result;
  }

  public async dispatch(
    name: StatefulProcessType,
    uuid: string,
    action: StatefulProcessActionsType,
    payload
  ): Promise<any> {
    this.storeEntity = 'statefulProcess';
    const result = await this.post(payload, `/${name}/${uuid}/dispatch/${action}`);
    return result;
  }

  public async getIncomplete(name: StatefulProcessType, payload): Promise<StatefulProcessDataT> {
    this.storeEntity = 'statefulProcess';
    const result = await this.post(payload, `/${name}/incomplete`);
    return result;
  }
}

export const statefulProcessService = new StatefulProcessService(fetch, API, cache, config);
