export enum LeadStatusType {
  /** Ожидает */
  WAITING = 'WAITING',

  /** Передано в клиентскую базу */
  TRANSFERED = 'TRANSFERED',

  /** Проверка */
  CHECK = 'CHECK',

  /** Пройдена проверка Росфинмониторинга */
  ROSFINMON_OK = 'ROSFINMON_OK',

  /** Одобрено скорингом Скориста */
  SCORISTA_OK = 'SCORISTA_OK',

  /** Пройдена проверка номера карты */
  CARD_CHECK_OK = 'CARD_CHECK_OK',

  /** Пройдена проверка клиента */
  CLIENT_CHECK_OK = 'CLIENT_CHECK_OK',

  /** Пройдена проверка компании */
  COMPANY_CHECK_OK = 'COMPANY_CHECK_OK',

  /** Пройдена проверка компьютера */
  PC_CHECK_OK = 'PC_CHECK_OK',

  /** Пройдена проверка мобильного телефона */
  MOBILE_CHECK_OK = 'MOBILE_CHECK_OK',

  /** Пройдена проверка паспорта ФМС */
  FMS_CHECK_OK = 'FMS_CHECK_OK',

  /** Выполнен запрос кредитной истории Equifax */
  EQUIFAX_OK = 'EQUIFAX_OK',

  /** Пройдена проверка e-mail адреса */
  EMAIL_CHECK_OK = 'EMAIL_CHECK_OK',

  /** Одобрено внутренним скорингом */
  INNER_SCORING_OK = 'INNER_SCORING_OK',

  /** Проверка по IP */
  IP_CHECK_OK = 'IP_CHECK_OK',

  /** Проверка по ИНН */
  INN_CHECK_OK = 'INN_CHECK_OK',

  /** Проверка по СНИЛС */
  SNILS_CHECK_OK = 'SNILS_CHECK_OK',

  /** Проверка по серии паспорта */
  PSP_SERIA_CHECK_OK = 'PSP_SERIA_CHECK_OK',

  /** Проверка по номеру паспорта */
  PSP_NO_CHECK_OK = 'PSP_NO_CHECK_OK',

  /** Проверка по дате рождения */
  BIRTHDATE_CHECK_OK = 'BIRTHDATE_CHECK_OK',

  /** Выполнен скоринг НБКИ */
  NBKI_SCORING_OK = 'NBKI_SCORING_OK',

  /** Выполнен скоринг НБКИ */
  CONSOLIDATION_OK = 'CONSOLIDATION_OK',

  /** Одобрено */
  APPROVED = 'APPROVED',

  /** Отказано */
  DENIED = 'DENIED',

  /** Не определено */
  UNDEFINED = 'UNDEFINED',

  /** Таймаут (при проверке лида) */
  TIMEOUT = 'TIMEOUT',

  /** Отказ клиента */
  CLIENT_REFUSAL = 'CLIENT_REFUSAL',

  /** Технический сбой */
  TECH_FAULT = 'TECH_FAULT',

  /** Прошла выдача */
  CONTRACT = 'CONTRACT',

  /** Ошибка валидации */
  VALIDATION_ERROR = 'VALIDATION_ERROR',
}
