import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';

class EntityService extends Entity {
  public async getList() {
    this.storeEntity = 'entity';
    const result = await this.fetch({});
    return result;
  }
}

export const entityService = new EntityService(fetch, API, cache, config);
