import { SCREEN_SIZE } from '@brainysoft/lk-components';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  chart: {
    position: 'relative',
    background: '#8ec7ec',
    width: 60,
    height: 250,
    margin: 'auto',
    zIndex: 2,
  },
  paidOff: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: (props) => props.paidOffPercent,
    width: '100%',
    background: '#008bd9',
  },
  topCaption: {
    fontSize: '0.875rem',
    color: '#8ec7f0',
    textAlign: 'center',
    marginBottom: 10,
  },
  bottomCaption: {
    fontSize: '0.875rem',
    color: '#008bd9',
    textAlign: 'center',
    marginTop: 10,
  },
  topSumm: {
    display: 'block',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: '#8ec7f0',
    lineHeight: '1',
  },
  bottomSumm: {
    display: 'block',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: '#008bd9',
    lineHeight: '1',
  },
  block: {
    position: 'relative',
    overflow: 'hidden',
  },
  chartShadow: {
    display: 'inline-block',
    width: 258,
    height: 258,
    transform: 'translateY(130px)',
    backgroundImage:
      'radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0), #ffffff), linear-gradient(to bottom, #d8d8d8, #d8d8d8)',
    boxShadow: 'inset 0 0 70px 50px #ffffff',
    position: 'absolute',
    bottom: 0,
    left: '15%',
    zIndex: 1,
    opacity: 0.5,
  },
  chartWrap: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    chart: {
      height: 125,
    },
    topSumm: {
      fontSize: '1.375rem',
      '& i': {
        fontSize: '1rem',
      },
    },
    bottomSumm: {
      fontSize: '1.375rem',
      '& i': {
        fontSize: '1rem',
      },
    },
    bottomCaption: {
      fontSize: '0.75rem',
      position: 'absolute',
      left: 10,
      bottom: 0,
      zIndex: 2,
    },
    topCaption: {
      fontSize: '0.75rem',
      position: 'absolute',
      right: 0,
      top: 0,
      width: 80,
      textAlign: 'left',
      zIndex: 2,
    },
    chartWrap: {
      position: 'relative',
    },
    chartShadow: {
      width: 175,
      height: 175,
      bottom: 60,
    },
  },
};

export default css;
