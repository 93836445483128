import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { VARIABLES } from '@brainysoft/lk-custom/variables';
import { SCREEN_SIZE } from '@brainysoft/lk-components';

const { greyLightText, greyText, primary, white, black } = THEME_COLORS;
const { generalBorder, generalBorderRadius, topBarHeight } = VARIABLES;

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  pageTitle: {
    color: black,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    margin: [0, 0, '1rem'],
    textAlign: 'center',
  },
  pageSubTitle: {
    lineHeight: 1.15,
    fontSize: '0.875rem',
    margin: '0 auto 1.5rem',
    textAlign: 'center',
    color: greyText,
    width: 300,
  },
  pageWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: `-${topBarHeight}`,
  },
  regButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    marginBottom: '2rem',
  },
  modal: {
    flex: 1,
    maxWidth: '360px',
    borderRadius: generalBorderRadius,
    boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.05)',
    background: white,
    margin: '2rem auto',
    padding: '1.5rem',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  formWrapper: {
    maxWidth: '600px',
  },
  formRow: {
    marginBottom: '1.25rem',
    position: 'relative',
  },
  phoneRow: {
    position: 'relative',
    '& .react-tel-input': {
      display: 'block',
      width: 350,
    },
    '& .react-tel-input input[type=tel]': {
      paddingLeft: '3rem',
      borderLeftWidth: 1,
      borderRadius: generalBorderRadius,
      '&:hover': {
        borderColor: primary,
      },
      '&:focus': {
        borderColor: primary,
        boxShadow: '0 0 0 2px rgba(0,120,220, 0.2)',
      },
    },
    '& .react-tel-input .flag-dropdown': {
      position: 'absolute',
      borderColor: 'transparent',
      background: 'transparent',
    },
    '& .react-tel-input .flag-dropdown .country-list': {
      width: 350,
    },
  },
  smsButtonWrapper: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  getSmsButton: {
    fontSize: '13px !important',
    display: 'flex',
    alignItems: 'center',
  },
  codeRow: {
    position: 'relative',
    '& .ant-collapse-content': {
      marginTop: '1rem',
    },
  },
  authInput: {
    display: 'block',
    borderRadius: generalBorderRadius,
    border: generalBorder,
    background: white,
    fontSize: '1rem',
    padding: '1.25rem',
    width: '100%',
    boxSizing: 'border-box',
    outline: 'none',
    '&::placeholder': {
      color: greyLightText,
    },
  },
  label: {
    display: 'block',
    fontSize: '0.8125rem',
    color: greyText,
  },
  errorMessage: {
    display: 'block',
    color: 'red',
    fontSize: '.75rem',
  },
  antdModal: {
    '& .ant-modal-title .title': {
      margin: 0,
    },
    '& .ant-modal-body': {
      fontSize: '0.9125rem',
    },
  },
  registrationLink: {
    cursor: 'pointer',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    modal: {
      width: 'auto',
      maxWidth: '360px',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    pageWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: `calc(-0.5 * ${topBarHeight})`,
    },
    modal: {
      width: '100%',
      maxWidth: '330px',
      padding: '0.875rem',
    },
    content: {
      padding: [0, '1.5rem'],
      boxSizing: 'border-box',
      marginTop: '15%',
    },
    sendSMS: {
      position: 'static',
      margin: ['1.5rem', 'auto', 0],
    },
    pageTitle: {
      fontSize: '1rem',
      margin: [0, 0, '1rem'],
    },
    authInput: {
      padding: ['0.6875rem', '1.25rem'],
      fontSize: '0.9375rem',
    },
    formRow: {
      '& .react-tel-input input[type=tel]': {
        padding: ['0.6875rem', '1.25rem', '0.6875rem', '3.125rem'],
        fontSize: '0.9375rem',
      },
    },
    pageSubTitle: {
      width: 'auto',
    },
    getSmsButton: {
      whiteSpace: 'normal',
      height: 'auto !important',
      paddingTop: '.25rem !important',
      paddingBottom: '.25rem !important',
    },
  },
};

export default css;
