import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { generalBorderRadius, generalTransition } from '@brainysoft/lk-custom/variables';
import {
  pageBackground,
  primaryBackground,
  greyLight,
  primary,
  secondary,
  secondaryBackground,
} from '@brainysoft/lk-custom/colors';
import Color from 'color';
/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  moneyMethod: {
    cursor: 'pointer',
    width: '150px',
    height: '150px',
    borderRadius: generalBorderRadius,
    border: `1px solid ${greyLight}`,
    position: 'relative',
    backgroundColor: pageBackground,
    marginRight: '1rem',
    marginBottom: '1rem',
    transition: generalTransition,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    textAlign: 'center',
    lineHeight: 1.2,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: Color(secondaryBackground).lighten(0.035).rgb().string(),
      boxShadow: '0 0 20px rgba(0,0,0,0.05)',
      border: `1px solid ${secondary}`,
      '& .addCardPlaceholder': {
        backgroundColor: primary,
        color: primary,
      },
    },
    '&.selected': {
      backgroundColor: Color(primaryBackground).lighten(0.035).rgb().string(),
      border: `1px solid ${primary}`,
      color: primary,
    },
  },
  cardNumber: {
    fontWeight: 'bold',
  },
  paySystem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '.875rem',
  },
  cardValidWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '.875rem',
    '& > *': {
      marginRight: '.5rem',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    card: {
      fontSize: '0.875rem',
      padding: ['0.3125rem', '1.25rem'],
    },
    moneyMethod: {
      width: '100%',
      height: 'auto',
      marginRight: 0,
    },
  },
};

export default css;
