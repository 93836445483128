import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';

class Client extends Entity {
  public async getClient() {
    this.storeEntity = 'client';
    const result = await this.fetch({}, '/info');
    return result;
  }

  public async getAvatar() {
    this.storeEntity = 'client';
    const result = await this.fetch({}, '/avatar');
    return result;
  }

  // public async getCard(cache: boolean | string = true) {
  //     this.storeEntity = 'client';
  //     const result = await this.fetch({}, `/card?${!cache || cache === 'true' ? 'cache=true' : 'cache=false'}`);
  //     return result;
  // }
}

export const clientService = new Client(fetch, API, cache, config);
