import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { green, pageBackground, white } from '@brainysoft/lk-custom/colors';
import { generalBorderRadius } from '@brainysoft/lk-custom/variables';

export const css = {
  surveysWrapper: {
    marginTop: '1rem',
    borderRadius: generalBorderRadius,
    background: white,
    padding: '1rem',
    textAlign: 'left',
  },
  widgetSurveysWrapper: {
    marginTop: '1rem',
  },
  processSurveysWrapper: {
    margin: '1rem -2rem',
    padding: '2rem',
    background: pageBackground,
  },
  surveyDisclaimer: {
    fontSize: '.75rem',
    lineHeight: 1.25,
  },
  submitButton: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
  },
  surveyResult: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h5': {
      color: green,
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    processSurveysWrapper: {
      margin: '1rem -1.5rem',
    },
  },
};

export default css;
