import { PeriodType } from '../enums';

export const ONE_HOUR_STALE = 60 * 60 * 1000;
export const TEN_MINUTES_STALE = 60 * 10 * 1000;
export const DEFAULT_PAGINATION_PAGE_SIZE = 10;

export const SMS_CHECK_INTERVAL = 1000;
export const SMS_CHECK_INTERVAL_SMEV = 60000;
export const PRINT_FORM_CHECK_INTERVAL = 3000;
export const PAYMENT_CHECK_INTERVAL = 10000;
export const PAYMENT_AVALABILITY_POSTPONE = 300;

export const DEFAULT_MIN_AMOUNT = 1000;
export const DEFAULT_MAX_AMOUNT = 10000;
export const DEFAULT_AMOUNT_STEP = 100;
export const DEFAULT_MIN_PERIOD = 5;
export const DEFAULT_MAX_PERIOD = 31;
export const DEFAULT_PERIOD_STEP = 1;
export const DEFAULT_PERIOD_UNIT = PeriodType.DAYS;

export const ENTER_KEY = 13;
