import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { greyLight, greyText, primary } from '@brainysoft/lk-custom/colors';
import { generalTransition } from '@brainysoft/lk-custom/variables';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  backButton: {
    display: 'inline-block',
    width: '1.0625rem',
    height: '1.875rem',
    background: 'url(/img/back.svg) no-repeat',
    marginRight: '1.25rem',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    outline: 'none',
  },
  document: {
    border: `1px solid ${greyLight}`,
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '.5rem',
    cursor: 'pointer',
    transition: generalTransition,
    color: greyText,
    '&>span>i': {
      marginRight: '1rem',
    },
    '&:hover': {
      color: primary,
      borderColor: primary,
    },
    '&.disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
      cursor: 'not-allowed',
      textDecoration: 'none',
    },
  },
  documentsInfo: {
    fontSize: '.875rem',
    lineHeight: 1.3,
  },
  documentsWrapper: {
    marginBottom: '2rem',
  },
  phoneMessage: {
    fontSize: '.875rem',
    lineHeight: 1.3,
    '& .ant-btn': {
      fontSize: '.875rem',
      padding: 0,
    },
  },
  success: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '& .header': {
      marginTop: 0,
      marginBottom: '2rem',
    },
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .ant-form-item': {
      marginBottom: '0 !important',
    },
    marginBottom: '.5rem',
  },
  checkboxLabel: {
    height: 'auto',
    minHeight: '2rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: '.875rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    backButton: {
      width: '0.625rem',
      height: '1.5625rem',
      margin: 0,
    },
  },
};

export default css;
