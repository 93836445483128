export enum RepaymentType {
  REGULAR = 'regular-repayment',
  PROLONGATION = 'prolongation',
  EARLY_REPAYMENT = 'early-repayment',
  INTEREST = 'interest-repayment',
  ARBITRARY = 'arbitrary-repayment',
}

export const isValidRepaymentType = (type: string) => {
  const repaymentTypes: string[] = Object.entries(RepaymentType).map(([key, value]) => value);
  return repaymentTypes.includes(type);
};
