import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { greyText, primary, white } from '@brainysoft/lk-custom/colors';

export const css = {
  phoneWrap: {
    flex: 1,
    textAlign: 'right',
    marginLeft: '1.875rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    '&.inside': {
      alignItems: 'flex-start',
      textAlign: 'left',
      '& $phoneNumber': {
        display: 'block',
        fontSize: '1.25rem',
        fontWeight: 'bold',
        color: white,
        textDecoration: 'none',
        marginLeft: 0,
        marginRight: '1rem',
        '&:hover, &:active, &:focus': {
          color: white,
          opacity: 0.9,
        },
        '&.inverted': {
          color: primary,
          '&:hover, &:active, &:focus': {
            color: primary,
          },
        },
      },
      '& $phoneDetail': {
        color: white,
        '&.inverted': {
          color: greyText,
        },
      },
    },
  },
  phoneBlockWrapper: {
    textAlign: 'right',
  },
  phoneNumber: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#4d4d4c',
    marginLeft: '.75rem',
    textDecoration: 'none',
    display: 'inline-block',
  },
  phoneDetail: {
    marginTop: '.25rem',
    display: 'block',
    fontSize: '0.8rem',
    color: '#6e6e6e',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    phoneNumber: {
      fontSize: '0.875rem',
    },
    phoneDetail: {
      fontSize: '0.625rem',
    },
    phoneWrap: {
      display: 'none',
    },
  },
};

export default css;
