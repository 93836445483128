import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { greyLight, white } from '@brainysoft/lk-custom/colors';
import { generalBorderRadius } from '@brainysoft/lk-custom/variables';

export const css = {
  leadWrapper: {
    padding: 0,
    background: white,
    borderRadius: generalBorderRadius,
    '& .ant-input-number': {
      fontSize: '2.5rem',
      '& .ant-input-number-input-wrap': {
        lineHeight: 1,
      },
    },
  },
  pageTitle: {
    fontSize: '1.5rem',
    marginTop: 0,
    textAlign: 'center',
    lineHeight: 1.1,
    marginBottom: '1.5rem',
  },
  formPlaceholder: {
    height: 275,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  slidersWrapper: {},
  headerWrapper: {
    marginBottom: '1.5rem',
  },
  header: {
    marginBottom: '.75rem',
  },
  subheader: {
    textAlign: 'center',
  },
  mobileButtonWrapper: {
    display: 'none',
  },
  formButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  creditProductsSwitchWrapper: {
    display: 'block',
  },
  unavalable: {
    textAlign: 'center',
  },
  lockDisclaimer: {
    color: greyLight,
    fontSize: '.75rem',
    marginBottom: '.5rem',
    textAlign: 'center',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    leadWrapper: {
      padding: 0,
    },
    widgetTitle: {
      textAlign: 'left',
      fontSize: '2rem',
    },
    formButtonWrapper: {
      display: 'none',
    },
    mobileButtonWrapper: {
      display: 'flex',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    leadWrapper: {
      padding: '0 !important',
    },
  },
};

export default css;
