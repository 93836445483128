import { SCREEN_SIZE } from '@brainysoft/lk-components';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  tableWrapper: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    borderSpacing: 0,
    border: '1px solid #d0d4d8',
    borderRadius: 8,
    overflow: 'hidden',
  },
  tableHead: {
    '& tr th': {
      fontSize: '.875rem',
      padding: '.75rem',
      textAlign: 'right',
      borderBottom: '1px solid #b0b4b8',
      color: '#555',
      fontWeight: 'normal',
    },
  },
  tableBody: {
    '& tr:nth-of-type(2n+1) td': {
      fontSize: '.875rem',
      padding: '.75rem',
      textAlign: 'right',
      borderBottom: '1px dotted #d0d4d8',
      background: '#fafbfc',
    },
    '& tr:nth-of-type(2n) td': {
      fontSize: '.875rem',
      padding: '.75rem',
      textAlign: 'right',
      borderBottom: '1px dotted #d0d4d8',
      background: '#fff',
    },
    '& tr:last-child td': {
      borderBottom: 'none',
    },
  },
  tableFooter: {
    '& tr th': {
      fontSize: '.9125rem',
      padding: '.75rem',
      textAlign: 'right',
      borderTop: '1px solid #b0b4b8',
      background: '#f0f8ff',
    },
  },
  scheduleType: {
    marginBottom: '1rem',
    fontSize: '.875rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
