import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { fontFamily, generalBorder, generalTableBorderRadius } from '@brainysoft/lk-custom/variables';
import { green, red } from '@brainysoft/lk-custom/colors';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  table: {
    width: '100%',
    borderSpacing: '0 5px',
    marginBottom: '1.5rem',
  },
  mobileonly: {
    display: 'none!important',
  },
  tableHeader: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    color: '#969696',
    '& th': {
      fontWeight: 'normal',
    },
  },
  tableBody: {
    '& tr': {
      border: generalBorder,
      borderRadius: '0.375rem',
      marginBottom: '0.3125rem',
    },
    '& td': {
      padding: ['1rem', '0.875rem'],
      borderTop: generalBorder,
      borderBottom: generalBorder,
      textAlign: 'center',
    },
    '& td:nth-child(1)': {
      borderLeft: generalBorder,
      borderRadius: `${generalTableBorderRadius} 0 0 ${generalTableBorderRadius}`,
    },
    '& td:last-child': {
      borderRight: generalBorder,
      borderRadius: `0 ${generalTableBorderRadius} ${generalTableBorderRadius} 0`,
    },
  },
  numberCell: {
    color: '#0078dc',
    textDecoration: 'underline',
    fontSize: '0.875rem',
    transition: '0.3s all ease',
    '&:hover': {
      color: '#006cc6',
    },
    '&:active': {
      color: '#0060b0',
    },
  },
  summCell: {
    color: '#131413',
    fontWeight: 'bold',
    fontSize: '1.375rem',
    '& i': {
      fontStyle: 'normal',
      fontSize: '1rem',
      fontFamily: fontFamily,
    },
  },
  dateCell: {
    fontSize: '0.875rem',
    color: '#4d4d4c',
  },
  methodCell: {
    fontSize: '0.875rem',
    color: '#4d4d4c',
  },
  fundTransactionStatus: {
    '&.accepted': {
      color: green,
    },
    '&.notAccepted': {
      color: red,
    },
  },
  mobileHeaderItem: {},
  compactTable: {
    width: '100%',
    borderSpacing: 0,
  },
  compactTableHeader: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    color: '#969696',
    lineHeight: 1,
    textAlign: 'left',
    '& th': {
      fontWeight: 'normal',
      padding: '.375rem .875rem',
    },
  },
  compactTableBody: {
    lineHeight: 1.25,
    '& tr': {
      border: 0,
      marginBottom: '0.3125rem',
    },
    '& td': {
      padding: ['1rem', '0.875rem'],
      borderBottom: generalBorder,
      // textAlign: 'center',
    },
    '& tr:first-child td': {
      borderTop: generalBorder,
    },
    '& tr:last-child td': {
      borderBottom: 0,
    },
    '& tr:nth-last-child(-n + 2) td[rowspan="2"]': {
      borderBottom: 0,
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    compactTable: {
      fontSize: '.75rem',
    },
    compactTableHeader: {
      '& th': {
        fontWeight: 'normal',
        padding: '.25rem .375rem',
      },
    },
    compactTableBody: {
      '& tr': {
        marginBottom: '.25rem',
      },
      '& td': {
        padding: ['.25rem', '.375rem'],
      },
    },
    tableHeader: {
      display: 'none',
    },
    mobileonly: {
      display: 'inline-block!important',
    },
    tableBody: {
      '& tr': {
        display: 'flex',
        flexWrap: 'wrap',
        padding: ['0.25rem', '0.75rem'],
        position: 'relative',
      },
      '& td': {
        border: 'none',
        width: '100%',
        textAlign: 'left',
        padding: ['0.5rem', 0],
      },
      '& td:nth-child(1)': {
        borderLeft: 'none',
        borderRadius: 0,
      },
      '& td:nth-child(4)': {
        borderRight: 'none',
        borderRadius: 0,
        padding: 0,
      },
      '& td:last-child': {
        borderRight: 'none',
        borderRadius: 0,
      },
    },
    mobileHeaderItem: {
      fontSize: '0.75rem',
      color: '#969696',
      background: 'transparent',
      fontWeight: 'normal',
      width: '35%',
    },
  },
};

export default css;
