import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { footerBackground, greyLightText, white } from '@brainysoft/lk-custom/colors';
import { generalTransition } from '@brainysoft/lk-custom/variables';

export const css = {
  footerWrapper: {
    background: footerBackground,
    boxShadow: '0 -2px 5px rgba(0,0,0,0.05)',
    display: 'flex',
    '& a': {
      lineHeight: 1.2,
      color: white,
      opacity: 0.8,
      transition: generalTransition,
      textDecoration: 'none',
      '&:hover': {
        opacity: 1,
      },
    },
  },
  footerContainer: {
    display: 'flex',
  },
  leftSide: {
    flexBasis: '33%',
    padding: '1.875rem 1rem 1.875rem 2rem',
    '& [class^="logoImg"]': {
      maxWidth: 150,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  rightSide: {
    flexBasis: '67%',
    padding: '1.875rem 2rem 1.875rem 1rem',
  },
  footerMenu: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    marginBottom: '2rem',
  },
  footerMenuItem: {
    listStyle: 'none',
    padding: '.25rem',
    fontSize: '.9125rem',
    '&:first-child': {
      paddingTop: 0,
    },
    '&:last-child': {
      paddingBottom: 0,
    },
    '& a': {
      fontSize: '0.9125rem',
    },
  },
  footerContactLabel: {
    color: greyLightText,
    fontSize: '.75rem',
  },
  footerContactContent: {
    color: white,
    marginBottom: '.25rem',
  },
  footerDisclaimer: {
    fontSize: '.75rem',
    color: greyLightText,
  },
  footerDisclaimerItem: {
    '&:not(:last-child)': {
      marginBottom: '.5rem',
    },
  },
  copyright: {
    fontSize: '.75rem',
    color: greyLightText,
    marginTop: '1rem',
    '& > :not(:last-child)': {
      marginBottom: '1rem',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    footerMenuItem: {
      '& a': {
        fontSize: '.875rem',
      },
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    footerWrapper: {
      flexDirection: 'column',
    },
    footerContainer: {
      flexDirection: 'column',
    },
    leftSide: {
      flexBasis: '100%',
      padding: ['1.25rem', '1.5rem'],
    },
    rightSide: {
      flexBasis: '100%',
      padding: ['1.25rem', '1.5rem'],
    },
    copyright: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '1rem',
    },
  },
};

export default css;
