import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { VARIABLES } from '@brainysoft/lk-custom/variables';

const { generalTransition } = VARIABLES;
const { black, blue, grey, greyLight, white } = THEME_COLORS;

export const css = {
  card: {
    cursor: 'pointer',
    width: '250px',
    height: '150px',
    borderRadius: '15px',
    border: `1px solid ${greyLight}`,
    position: 'relative',
    backgroundColor: 'rgb(245,246,247)',
    marginBottom: '1rem',
    transition: generalTransition,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '.5rem .75rem',
    color: black,
    '&.placeholder': {
      width: '200px',
      height: '120px',
      background: white,
      borderStyle: 'dashed',
      color: grey,
    },
    '& .addCardWrapper': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    '& .loadingWrapper': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    '& .addCardPlaceholder': {
      marginBottom: '.5rem',
      '& .anticon': {
        fontSize: '1.5rem',
      },
    },
    '&:hover': {
      backgroundColor: 'rgb(240,250,255)',
      boxShadow: '0 0 20px rgba(0,0,0,0.075)',
    },
    '&.selected': {
      backgroundColor: 'rgb(240,250,255)',
      border: `1px solid ${blue}`,
    },
    '&.disabled': {
      pointerEvents: 'none',
      opacity: '.6',
    },
  },
  cardNumber: {
    fontSize: '1.125rem',
    letterSpacing: 1.25,
    fontWeight: 'bold',
  },
  paySystem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '.875rem',
  },
  cardValidWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '.875rem',
    '& > *': {
      marginRight: '.5rem',
    },
  },
  pendingWrapper: {
    fontSize: '.75rem',
    textAlign: 'center',
    marginTop: '1rem',
    color: grey,
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    card: {
      fontSize: '1rem',
      padding: ['.75rem', '1rem'],
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    card: {
      fontSize: '0.875rem',
      padding: ['.75rem', '1rem'],
    },
  },
};

export default css;
