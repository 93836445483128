import { green } from '@brainysoft/lk-custom/colors';

export const animation = {
  '@keyframes pulse': {
    '0%': {
      opacity: 0.6,
      transform: 'scale(.6)',
    },
    '33%': {
      opacity: 1,
      transform: 'scale(1)',
    },
    '66%': {
      opacity: 0.6,
      transform: 'scale(.6)',
    },
    '100%': {
      opacity: 0.6,
      transform: 'scale(.6)',
    },
  },
  '@keyframes stroke': {
    '100%': {
      strokeDashoffset: 0,
    },
  },
  '@keyframes scale': {
    '0%': {},
    '100%': {
      transform: 'none',
    },
    '50%': {
      transform: 'scale3d(1.1, 1.1, 1)',
    },
  },
  '@keyframes fill': {
    '100%': {
      boxShadow: `inset 0 0 0 100vh ${green}`,
    },
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
};
