export enum DictionaryType {
  GETTING_MONEY_METHOD = 'getting-money-methods',
  CREDIT_PRODUCTS = 'credit-products',
  SUBDIVISIONS = 'subdivisions',
  EDUCATION = 'educations',
  PENSION_TYPE = 'pension-types',
  RELATIVE_TYPE = 'relative-types',
  EMPLOYMENT_RATE = 'employment-rates',
  EMPLOYMENT_TYPE = 'employment-types',
  ACTIVITY_TYPE = 'activity-types',
  CREDIT_PURPOSE = 'credit-purposes',
  COUNTRIES = 'countries',
  PROFESSIONS = 'professions',
  INCOME_TYPES = 'income-types',
  PROPERTY_TYPES = 'property-types',
  ADDRESS_PURPOSES = 'address-purposes',
  COMPLEMENTARY_DOC_TYPES = 'complementary-doc-types',
  TOBACCO_BRANDS = 'custom/tobacco-brands',
  TOBACCO_SUBBRANDS = 'custom/tobacco-subbrands',
  HOW_YOU_KNOWS = 'how-you-knows',
}
