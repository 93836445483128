import { parseCookies as getCookie, setCookie, destroyCookie as deleteCookie } from 'nookies';

export const localStorePrefix = 'BRAINYSOFT.APP.';
export const sessionCookie = 'bslk_session';
export const userCookie = 'bslk_user';
export const consumerUuidCookie = 'cons';

class Token {
  constructor() {
    this.storageKey = `${localStorePrefix}token`;
    this.token = this.getTokenFromStorage();
  }

  public storageKey: string;
  public token: string | null;

  public getTokenFromStorage(ctx: any = null) {
    try {
      return getCookie(ctx)[this.storageKey];
    } catch (err) {
      return null;
    }
  }

  public setTokenToStorage(token: string, ctx: any = null) {
    try {
      this.set(token);
      const expires = 24 * 60 * 60; // 1 day
      setCookie(ctx, this.storageKey, token, {
        maxAge: expires,
        path: '/',
      });
    } catch (err) {
      console.log(err);
    }
  }

  public removeTokenFromStorage(ctx: any = null) {
    try {
      this.token = null;
      deleteCookie(ctx, this.storageKey);
    } catch (err) {
      console.log(err);
    }
  }

  public get(ctx: any = null) {
    return this.getTokenFromStorage(ctx) || this.token;
  }

  public set(token: string) {
    this.token = token;
  }

  public logout(ctx: any = null) {
    this.removeTokenFromStorage(ctx);
    try {
      deleteCookie(ctx, sessionCookie);
      deleteCookie(ctx, userCookie);
      deleteCookie(ctx, consumerUuidCookie);
    } catch (err) {
      console.log(err);
    }
  }
}

export default new Token();

export const tokenService = new Token();
