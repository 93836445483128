export enum SexType {
  MALE = 101251, //'Мужчина'
  FEMALE = 101252, //'Женщина'
}

export const getSexTypeId = (code) => {
  switch (code) {
    case 'MALE':
      return SexType.MALE;
    case 'FEMALE':
      return SexType.FEMALE;
  }
  console.error(`Unknown sexType code ${code}`);
  return;
};

export const getCodeBySexTypeId = (id) => {
  switch (id) {
    case SexType.MALE:
      return 'MALE';
    case SexType.FEMALE:
      return 'FEMALE';
  }
  console.error(`Unknown sexTypeId ${id}`);
  return;
};
