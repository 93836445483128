import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { greyLight, greyText, primary } from '@brainysoft/lk-custom/colors';
import { generalTransition } from '@brainysoft/lk-custom/variables';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  documentBlock: {
    border: `1px solid ${greyLight}`,
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '.5rem',
    cursor: 'pointer',
    transition: generalTransition,
    color: greyText,
    '&>span>.anticon': {
      marginRight: '1rem',
    },
    '&:hover': {
      color: primary,
      borderColor: primary,
    },
    '&.disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
      cursor: 'not-allowed',
      textDecoration: 'none',
    },
  },
  documentLink: {
    padding: '0 .5rem 0 0',
    display: 'inline-block',
    cursor: 'pointer',
    transition: generalTransition,
    color: primary,
    '&>span>.anticon': {
      marginRight: '.5rem',
    },
    '&:hover': {
      color: primary,
    },
    '&.disabled': {
      opacity: 0.5,
      color: greyText,
      pointerEvents: 'none',
      cursor: 'not-allowed',
      textDecoration: 'none',
    },
  },
  documentsBlockWrapper: {
    marginBottom: '2rem',
    '&.mb0': {
      marginBottom: 0,
    },
  },
  documentsLinkWrapper: {
    display: 'inline',
  },
  documentContentWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  documentIcon: {
    marginRight: '.5rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
