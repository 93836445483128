import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { white } from '../../../colors';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  button: {
    display: 'inline-block',
    width: '17.9375rem',
    height: '2.8125rem',
    lineHeight: '2.8125rem',
    borderRadius: '1.375rem',
    background: '#e60000',
    textAlign: 'center',
    color: '#fefefe',
    textDecoration: 'none',
    fontSize: '1.0625rem',
    fontWeight: 'bold',
    transition: '0.3s all ease',
    cursor: 'pointer',
    '&:hover': {
      background: '#cf0000',
      color: white,
    },
    '&:active': {
      background: '#b80000',
      color: white,
    },
    '&:focus': {
      background: '#b80000',
      color: white,
    },
  },
  antdModal: {
    '& .ant-modal-title .title': {
      margin: 0,
    },
    '& .ant-modal-body': {
      fontSize: '0.9125rem',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    // button: {
    //     width: 235,
    // }
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    button: {
      width: '5.125rem',
      height: '1.75rem',
      lineHeight: '1.75rem',
      fontSize: '0.875rem',
    },
  },
};

export default css;
