import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';
import { InnDTO } from '../../interfaces';

class DataSourcesService extends Entity {
  public async requestInn(data: {
    clientId?: number;
    leadId?: number;
    objects: { client?: Record<string, unknown> };
  }): Promise<InnDTO> {
    this.storeEntity = 'dataSources';
    return await this.post(data, '/check/inn');
  }
}

export const dataSourcesService = new DataSourcesService(fetch, API, cache, config);
