import { SCREEN_SIZE } from '@brainysoft/lk-components';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  antdModal: {
    '& .ant-modal-title .title': {
      margin: 0,
    },
    '& .ant-modal-body': {
      fontSize: '0.9125rem',
    },
    '& .ant-modal-footer': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .ant-modal-close .anticon': {
      lineHeight: 'inherit',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    antdModal: {
      '& .ant-modal-title .title': {
        fontSize: '1rem',
      },
    },
  },
};

export default css;
