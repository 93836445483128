import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { generalTransition, maxWidth, topBarHeight } from '@brainysoft/lk-custom/variables';
import { greyText, primary, white } from '@brainysoft/lk-custom/colors';
import { topBarHeightMobile } from '@brainysoft/lk-custom/variables';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  header: {
    height: topBarHeight,
    background: primary,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '& > *': {
      position: 'relative',
    },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: 'linear-gradient(-5deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 70%)',
    },
    '&.inverted': {
      background: white,
      boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
      '&::before': {
        content: 'none',
      },
    },
  },
  headerWrap: {
    margin: '0 auto',
    width: '100%',
    maxWidth: maxWidth,
    padding: '1rem 2rem',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  phoneMenuWrapper: {
    flexBasis: '75%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
  },
  // phoneWrap: {
  //     display: 'flex',
  //     flexDirection: 'column',
  //     alignItems: 'flex-start',
  //     justifyContent: 'center',
  //     verticalAlign: 'middle',
  // },
  // phoneNumber: {
  //     display: 'block',
  //     fontSize: '1.25rem',
  //     fontWeight: 'bold',
  //     color: white,
  //     textDecoration: 'none',
  //     marginRight: '1rem',
  //     '&:hover, &:active, &:focus': {
  //         color: white,
  //         opacity: 0.9,
  //     },
  //     '&.inverted': {
  //         color: primary,
  //         '&:hover, &:active, &:focus': {
  //             color: primary,
  //         },
  //     },
  // },
  // phoneDetail: {
  //     display: 'block',
  //     fontSize: '0.75rem',
  //     color: white,
  //     opacity: 0.7,
  //     marginTop: '.25rem',
  //     '&.inverted': {
  //         color: greyText,
  //     },
  // },
  mobileOnly: {
    display: 'none',
  },
  closeMenuBtnWrapper: {
    display: 'block',
    cursor: 'pointer',
    position: 'absolute',
    top: '1.75rem',
    right: '1.75rem',
  },
  closeMenuBtn: {
    display: 'block',
    width: '1.25rem',
    height: '2px',
    background: 'transparent',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    transition: generalTransition,
    '&::before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      background: white,
      width: '1.25rem',
      height: '2px',
      transition: generalTransition,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%) rotate(405deg)',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      background: white,
      width: '1.25rem',
      height: '2px',
      transition: generalTransition,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%) rotate(-405deg)',
    },
  },
  burgerMenuBtnWrapper: {
    display: 'block',
    cursor: 'pointer',
  },
  burgerMenuBtn: {
    display: 'block',
    width: '1.25rem',
    height: '2px',
    background: white,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    transition: generalTransition,
    '&::before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      background: white,
      top: '-0.5rem',
      left: '0',
      width: '1.25rem',
      height: '2px',
      transition: generalTransition,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      background: white,
      top: '0.5rem',
      left: '0',
      width: '1.25rem',
      height: '2px',
      transition: generalTransition,
    },
    '&.inverted': {
      background: primary,
      '&::before': {
        background: primary,
      },
      '&::after': {
        background: primary,
      },
    },
  },
  offcanvasMenu: {
    display: 'none',
    background: primary,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '9998',
    '&.active': {
      position: 'fixed',
      display: 'flex',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  menuWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingLeft: '1rem',
  },
  profile: {
    width: '2rem',
    height: '2rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    burgerMenuBtnWrapper: {
      display: 'block',
      width: '1.25rem',
      height: '1.25rem',
      position: 'relative',
    },
    headerWrap: {
      padding: '0.9375rem 1.5rem',
    },
    logoWrapper: {
      padding: '0 1.5rem',
      flexBasis: 'auto',
    },
    phoneMenuWrapper: {
      padding: 0,
      justifyContent: 'space-between',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    header: {
      height: topBarHeightMobile,
    },
    // phoneWrap: {
    //     display: 'none',
    // },
    headerWrap: {
      padding: '0.9375rem 1.5rem',
    },
    phoneMenuWrapper: {
      justifyContent: 'flex-end',
    },
  },
};

export default css;
