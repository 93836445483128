import * as Noty from 'noty';

Noty.overrideDefaults({
  layout: 'topRight',
  theme: 'mint',
  timeout: 7000,
  // animation: {
  //     open : 'animated fadeInRight',
  //     close: 'animated fadeOutRight'
  // }
});

const _Noty: any = Noty;

function message(type, title, options = {}) {
  const notyOptions: Noty.Options = {
    type: type,
    text: title,
    ...options,
  };
  new _Noty(notyOptions).show();
}

export const notify = {
  message: (type, msg) => {
    if (typeof notify[type] == 'function') notify[type](msg);
    else {
      console.log('Undefined notification type', type);
      notify['alert'](msg);
    }
  },

  alert: (msg) => {
    message('alert', msg, { timeout: 7000 });
  },

  success: (msg) => {
    message('success', msg, { timeout: 7000 });
  },

  warning: (msg) => {
    message('warning', msg, { timeout: 7000 });
  },

  info: (msg) => {
    message('info', msg, { timeout: 7000 });
  },

  error: (msg, activityLabel: string | null = null) => {
    // message('error', errorMessageHtml(msg, activityLabel), { timeout: 7000 });
    message('error', activityLabel ? errorMessageHtml(msg, activityLabel) : msg, { timeout: 7000 });
  },

  fatal: (msg, exception) => {
    console.log(msg);
    console.warn(exception);
    message('error', msg, { timeout: 14000 });
  },
};

const errorMessageHtml = (msg, activityLabel) => {
  return (
    '<div class="errorWrapper">\n' +
    '   <div class="errorText">' +
    msg +
    '</div>\n' +
    '   <div class="errorActivityLabel">' +
    activityLabel +
    '</div>\n' +
    '</div>'
  );
};
