import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';

class Consumer extends Entity {
  public async getLegalEntityInfo() {
    this.storeEntity = 'getLegalEntityInfo';
    const result = await this.fetch();
    return result;
  }
}

export const consumerService = new Consumer(fetch, API, cache, config);
