import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { generalBorder } from '@brainysoft/lk-custom/variables';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  profile: {
    marginLeft: '.5rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  popoverWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  profileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '.75rem',
    paddingBottom: '.75rem',
    borderBottom: generalBorder,
    width: '100%',
  },
  personalData: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fioWrapper: {
    '& :not(:last-child)': {
      marginRight: '.375em',
    },
  },
  avatarWrapper: {
    maxWidth: 50,
    maxHeight: 50,
    '& > *': {
      width: '100%',
      height: '100%',
    },
    marginBottom: '.5rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    leftSide: {
      display: 'none',
    },
    rightSide: {
      width: '100%',
      padding: ['1.25rem', '0.9375rem'],
    },
  },
};

export default css;
