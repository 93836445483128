import { SCREEN_SIZE } from '@brainysoft/lk-components';
// import { generalBorderRadiusLarge, generalTransition } from '../../styles/variables';
// import { grey, greyDark, greyLight, greyText, primary } from '../../styles/colors';

export const css = {
  pagination: {
    '& .ant-pagination-item': {
      borderRadius: '500px !important',
    },
    '& .ant-pagination-item-link': {
      borderRadius: '500px !important',
    },
  },
  paginationWrapper: {
    margin: '1rem 0',
    display: 'flex',
    justifyContent: 'center',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
