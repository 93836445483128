import { SCREEN_SIZE } from '@brainysoft/lk-components';
// import { generalBorderRadiusLarge, generalTransition } from '../../styles/variables';
// import { grey, greyDark, greyLight, greyText, primary } from '../../styles/colors';

export const css = {
  steps: {
    marginTop: '2rem',
    marginBottom: '2rem',
    '& .ant-steps-item-description': {
      fontSize: '.8125rem',
      lineHeight: 1.2,
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    steps: {
      display: 'none !important',
    },
  },
};

export default css;
