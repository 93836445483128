export enum PhotoType {
  RUS_PASSPORT_PERSONAL_DATA = 'rus_passport_national__personal_data',
  RUS_PASSPORT_REGISTRATION = 'rus_passport_national__registration',
  RUS_PASSPORT_REGISTRATION2 = 'rus_passport_national__registration2',
  RUS_PASSPORT_REGISTRATION3 = 'rus_passport_national__registration3',
  RUS_PASSPORT_REGISTRATION_NEXT = 'rus_passport_national__registration_next',
  RUS_PASSPORT_MARITAL_STATUS = 'rus_passport_national__marital_status',
  RUS_PASSPORT_CHILDREN = 'rus_passport_national__children',
  RUS_PASSPORT_OLD_PASSPORTS = 'rus_passport_national__old_passports',

  FOREIGN_PASSPORT_PERSONAL_DATA = 'foreign_passport__personal_data',
  FOREIGN_PASSPORT_REGISTRATION = 'foreign_passport__registration',
  PATENT_PAGE1 = 'patent__page1',
  PATENT_PAGE2 = 'patent__page2',
  GREEN_CARD = 'green_card', //вид на жительство
  RESIDENCE_PERMIT = 'residence_permit', //разрешение на проживание
  ANY_PROOFING_DOCUMENT = 'any_proofing_document',
  DRIVING_LICENSE = 'driving_license',
  MARRIAGE_CERTIFICATE = 'marriage_certificate',
  BIRTH_CERTIFICATE = 'birth_certificate',
  RUS_MIGRATION_CARD = 'rus_migration_card__registration',
  RUS_REGISTRATION_PAGE1 = 'rus_registration__page1', // регистрация в России для иностранных граждан
  RUS_REGISTRATION_PAGE2 = 'rus_registration__page2', // оборотная сторона ^
  SELFIE = 'selfie',
  CARD = 'card',
  SNILS = 'snils',

  SELFIE_SNILS_RECOVERY = 'selfie_snils_recovery',
  SELFIE_RECOVERY = 'selfie_recovery',
}
