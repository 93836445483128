import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { VARIABLES } from '@brainysoft/lk-custom/variables';

const { red } = THEME_COLORS;
const { generalBorderRadius } = VARIABLES;

export const css = {
  fieldArrayHeader: {
    marginBottom: '1rem',
  },
  fieldArrayWrapper: {},
  fieldArrayRow: {
    display: 'flex',
  },
  fieldArrayRowContent: {
    flex: '1 0 calc(100% - 32px)',
  },
  fieldArrayDeleteButton: {
    flex: 0,
    flexBasis: 32,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1rem',
    paddingBottom: '1rem',
    '& .ant-btn': {
      paddingLeft: '.5rem',
      paddingRight: '.5rem',
    },
  },
  fieldArrayAddButton: {
    '& .ant-btn': {
      paddingLeft: '.5rem',
      paddingRight: '.5rem',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
