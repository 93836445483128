import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';

class KycService extends Entity {
  public async smevInit(leadId) {
    this.storeEntity = 'kyc';
    return await this.post({ leadId: leadId }, '/smev/init');
  }

  public async smevProof(uuid, code) {
    this.storeEntity = 'kyc';
    return await this.post({ smsCode: code }, `/smev/${uuid}/proof`);
  }
}

export const kycService = new KycService(fetch, API, cache, config);
