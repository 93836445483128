import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';

class SurveysService extends Entity {
  public async surveys() {
    this.storeEntity = 'surveys';
    const result = await this.fetch({});
    return result;
  }

  public async result(id: number, data: Record<string, unknown>) {
    this.storeEntity = 'surveys';
    const result = await this.post(data, `/${id}`);
    return result;
  }
}

export const surveysService = new SurveysService(fetch, API, cache, config);
