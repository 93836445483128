import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { VARIABLES } from '@brainysoft/lk-custom/variables';
import Color from 'color';

const { generalBorderRadius, inputBorderColor } = VARIABLES;
const { greyText } = THEME_COLORS;

export const css = {
  input: {
    borderRadius: generalBorderRadius,
    fontSize: '1rem',
    padding: '1rem 1.25rem',
    height: 'auto',
    width: '100%',
    color: greyText,
    '&:not(.disabled)': {
      '&:hover, &:active': {
        borderColor: inputBorderColor,
      },
      '&:focus': {
        borderColor: inputBorderColor,
        boxShadow: `0 0 0 2px ${Color(inputBorderColor).rgb().fade(0.8).string()}`,
      },
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    input: {},
  },
};

export default css;
