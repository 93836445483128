import { css as original } from '@custom/defaults/theme/Components/AuthPage/styles';
import { merge } from 'lodash';

export const css = merge(original, {
  authBackgroundImage: {
    '&:before': {
      background: 'linear-gradient(0deg, rgba(0,0,0,0) 70%, rgba(0,0,0,0.2) 100%)',
    },
  },
});

export default css;
