import { jivoService } from '@brainysoft/lk-components';
import { useQuery } from 'react-query';
import { QueryClient } from 'react-query/core';
// eslint-disable-next-line no-unused-vars
import { UseQueryResult } from 'react-query/types/react/types';

export const useJivo: () => UseQueryResult<{ isJivo: boolean }> = () => {
  return useQuery(['isJivo'], function () {
    return jivoService.isJivo() as Promise<boolean>;
  });
};

export const refreshJivo: (queryClient: QueryClient) => void = (queryClient) => {
  return queryClient.invalidateQueries(['jivo']);
};
