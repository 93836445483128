import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { primary, white } from '@brainysoft/lk-custom/colors';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileIcon: {
    '& circle, & path': {
      stroke: white,
    },
    '&.inverted': {
      '& circle, & path': {
        stroke: primary,
      },
    },
  },
  externalLinkIcon: {
    '& > svg > *': {
      stroke: primary,
    },
    '&.white > svg > *': {
      stroke: white,
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
