import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { primary, white } from '@brainysoft/lk-custom/colors';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  backButton: {
    display: 'inline-block',
    width: '1.0625rem',
    height: '1.875rem',
    background: 'url(/img/back.svg) no-repeat',
    marginRight: '1.25rem',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    outline: 'none',
  },
  profileIcon: {
    '& circle, & path': {
      stroke: white,
    },
    '&.inverted': {
      '& circle, & path': {
        stroke: primary,
      },
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    backButton: {
      width: '0.625rem',
      height: '1.5625rem',
      margin: 0,
    },
  },
};

export default css;
