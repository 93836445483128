import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { generalBorder } from '@brainysoft/lk-custom/variables';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  pageWrapper: {
    background: '#e6e7e8',
    maxWidth: 1200,
    margin: 'auto',
  },
  leftSide: {
    display: 'inline-block',
    width: '25%',
    padding: ['1.875rem', 0, '1.875rem', '2rem'],
    boxSizing: 'border-box',
    verticalAlign: 'top',
  },
  rightSide: {
    display: 'inline-block',
    width: '75%',
    padding: ['1.875rem', '2rem', '1.875rem', '2%'],
    boxSizing: 'border-box',
    verticalAlign: 'top',
  },
  wrap: {
    background: '#ffffff',
    borderRadius: '0.375rem',
  },
  pageTitle: {
    fontSize: '2.25rem',
    fontWeight: 'bold',
    color: '#000000',
    borderBottom: 'solid 1px #e6e7e8',
    padding: '1rem 2rem',
    margin: 0,
    position: 'relative',
  },
  content: {
    boxSizing: 'border-box',
    padding: '1.5rem',
  },
  infoWrap: {
    display: 'inline-block',
    width: 'calc(70% - 5em)',
    verticalAlign: 'top',
  },
  chartWrap: {
    display: 'inline-block',
    width: '30%',
    verticalAlign: 'top',
    marginLeft: '5rem',
  },
  tableWrapper: {
    marginTop: '1rem',
  },
  buttonWrapper: {
    display: 'flex',
    '& .ant-btn': {
      marginRight: '1rem',
    },
  },
  backBtn: {
    position: 'absolute',
    left: 16,
    top: 20,
  },
  scheduleTable: {
    marginTop: '1.25rem',
  },
  paymentsTable: {
    marginTop: '1.25rem',
  },
  mobileColumn: {},
  visible: {
    display: 'block',
  },
  skeleton: {
    '& .ant-skeleton-content .ant-skeleton-paragraph > li': {
      height: '1.25rem',
      marginBottom: '1.5rem',
    },
  },
  collapse: {
    borderRadius: 0,
    '& .ant-collapse-item': {
      borderTop: generalBorder,
    },
    '& .ant-collapse-header': {
      padding: '1rem 1.75rem 1rem 0 !important',
      fontWeight: 'bold',
    },
    '& .ant-collapse-content > .ant-collapse-content-box': {
      padding: '0 0 1rem 0 !important',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    // leftSide: {
    //     display: 'none'
    // },
    // rightSide: {
    //     width: '100%',
    //     padding: [20, 15]
    // },
    // greeting: {
    //     margin: '0 0 15px',
    //     fontSize: 30
    // }
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    leftSide: {
      display: 'none',
    },
    rightSide: {
      width: '100%',
      padding: ['1.25rem', '0.9375rem'],
    },
    pageTitle: {
      fontSize: '1.125rem',
      padding: ['0.8125rem', '1rem', '0.8125rem', '2.25rem'],
      position: 'relative',
    },
    infoWrap: {
      width: '100%',
    },
    chartWrap: {
      width: '100%',
      marginBottom: '1.25rem',
    },
    mobileColumn: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    content: {
      padding: ['1.25rem', '0.8125rem'],
    },
    btnWrap: {
      marginTop: '1.25rem',
    },
    backBtn: {
      top: 12,
    },
  },
};

export default css;
