import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';

class DocumentsService extends Entity {
  public async getAll() {
    this.storeEntity = 'documents';
    const result = await this.fetch('');
    return result;
  }

  public async fileDownloadProxy(fileId: number) {
    this.storeEntity = 'documents';
    const result = await this.download('', `/${fileId}/download`);
    return result;
  }
}

export const documentService = new DocumentsService(fetch, API, cache, config);
