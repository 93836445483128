import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { white } from '@brainysoft/lk-custom/colors';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  headerWrap: {
    width: '100%',
    margin: '0 auto',
    maxWidth: 'auto',
    padding: '1rem 1.5rem',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: white,
    boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
  },
  // phoneWrap: {
  //     flex: 1,
  //     display: 'inline-block',
  //     marginLeft: '1.875rem',
  //     verticalAlign: 'middle',
  //     textAlign: 'right',
  // },
  // phoneNumber: {
  //     display: 'block',
  //     fontSize: '1.5rem',
  //     fontWeight: 'bold',
  //     color: '#4d4d4c',
  //     textDecoration: 'none',
  // },
  // phoneDetail: {
  //     marginTop: '.25rem',
  //     display: 'block',
  //     fontSize: '0.8rem',
  //     color: '#6e6e6e',
  // },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    headerWrap: {
      padding: '0.9375rem',
    },
    // phoneNumber: {
    //     fontSize: '0.875rem',
    // },
    // phoneDetail: {
    //     fontSize: '0.625rem',
    // },
    // phoneWrap: {
    //     display: 'none',
    // },
  },
};

export default css;
