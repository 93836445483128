export enum LoanAppStatusType {
  /**
   * Создана
   */
  CREATED = 101541,

  /**
   * Отклонена
   */
  DENIED = 101542,

  /**
   * Выдана
   */
  SATISFIED = 101543,

  /**
   * К выдаче
   */
  CONTRACT = 101544,

  /**
   * Ожидает рассмотрения
   */
  WAITING_CONSIDERATION = 101545,

  /**
   * В рассмотрении
   */
  IN_CONSIDERATION = 101546,

  /**
   * Автоматическая проверка
   */
  AUTO_VERIFICATION = 101547,

  /**
   * Отказ клиента
   */
  CLIENT_REFUSAL = 101548,
}
