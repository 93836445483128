import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { primary, white } from '@brainysoft/lk-custom/colors';
import { generalBorderRadius } from '@brainysoft/lk-custom/variables';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  messageWrapper: {
    fontSize: '.875rem',
    lineHeight: 1.4,
    margin: '2rem 0',
    background: primary,
    color: white,
    padding: '1rem',
    borderRadius: generalBorderRadius,
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
