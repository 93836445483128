import { SCREEN_SIZE } from '@brainysoft/lk-components';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  disembursement: {},
  success: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '& .header': {
      marginTop: 0,
      marginBottom: '1.5rem',
    },
  },
  fail: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '& .header': {
      marginTop: 0,
      marginBottom: '1.5rem',
    },
  },
  header: {
    marginTop: 0,
    marginBottom: '1.5rem',
    textAlign: 'center',
  },
  descriptor: {
    margin: '2rem 0 0',
    textAlign: 'center',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
