import { blue, green, grey, greyDark, greyLight, red, yellow } from '@brainysoft/lk-custom/colors';
import Color from 'color';
import { generalBorderRadius } from '@brainysoft/lk-custom/variables';
import { SCREEN_SIZE } from '@brainysoft/lk-components';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  message: {
    width: '100%',
    padding: '1rem',
    marginBottom: '1rem',
    fontSize: '.875rem',
    borderRadius: generalBorderRadius,

    '&.neutral': {
      color: greyDark,
      border: `1px solid ${Color(greyLight).lighten(0.0).rgb().string()}`,
      background: Color(greyLight).lighten(0.15).rgb().string(),
    },
    '&.info': {
      color: blue,
      border: `1px solid ${Color(blue).lighten(0.5).rgb().string()}`,
      background: Color(blue).lighten(1.2).rgb().string(),
    },
    '&.error': {
      color: red,
      border: `1px solid ${Color(red).lighten(0.5).rgb().string()}`,
      background: Color(red).lighten(0.95).rgb().string(),
      '& a': {
        color: red,
        textDecoration: 'underline',
      },
    },
    '&.warning': {
      color: yellow,
      border: `1px solid ${Color(yellow).lighten(0.5).rgb().string()}`,
      background: Color(yellow).lighten(0.95).rgb().string(),
    },
    '&.success': {
      color: green,
      border: `1px solid ${Color(green).lighten(0.0).rgb().string()}`,
      background: Color(green).lighten(1.6).rgb().string(),
    },

    '&.small': {
      padding: '.5rem',
      fontSize: '.75rem',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
