import { mergeThemeVariables } from '@brainysoft/lk-custom-kernel/utils/merge-theme-variables';
import { variables } from './theme/Styles';

export const VARIABLES = mergeThemeVariables(variables);

export const logo = VARIABLES.logo;
export const logoInverted = VARIABLES.logoInverted;
export const logoAuth = VARIABLES.logoAuth;
export const logoFooter = VARIABLES.logoFooter;
export const authBackground = VARIABLES.authBackground;
export const maxWidth = VARIABLES.maxWidth;
export const fontFamily = VARIABLES.fontFamily;
export const topBarHeight = VARIABLES.topBarHeight;
export const topBarHeightMobile = VARIABLES.topBarHeightMobile;
export const generalBorder = VARIABLES.generalBorder;
export const generalBorderLight = VARIABLES.generalBorderLight;
export const generalBorderRadius = VARIABLES.generalBorderRadius;
export const generalBorderRadiusLarge = VARIABLES.generalBorderRadiusLarge;
export const generalTransition = VARIABLES.generalTransition;
export const generalTableBorderRadius = VARIABLES.generalTableBorderRadius;
export const loaderBarColor = VARIABLES.loaderBarColor;
export const fontSizeBase = VARIABLES.fontSizeBase;
export const fontSizeLarge = VARIABLES.fontSizeLarge;
export const fontSizeSmall = VARIABLES.fontSizeSmall;
export const inputBorderColor = VARIABLES.inputBorderColor;
