import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';

export type UpdateSubscriptions = {
  marketing: {
    email: boolean;
    sms: boolean;
  };
  operational: {
    email: boolean;
    sms: boolean;
  };
};

export type UpdateSubscriptionsDTO = {
  'marketing.sms': boolean;
  'marketing.email': boolean;
  'operational.sms': boolean;
  'operational.email': boolean;
};

export type VerificationResponseType = {
  uuid: string;
  verified: boolean;
  verified_at: string;
};

export type VerificationExistsType = {
  found: boolean;
};

export type VerificationEntityType = 'client' | 'lead' | 'process';

class Messages extends Entity {
  public async getSubscriptions() {
    this.storeEntity = 'subscriptions';
    const result = await this.fetch();
    return result;
  }

  public async updateSubscription(code, value) {
    this.storeEntity = 'subscriptions';
    const result = await this.post({ value }, `/${code}`);
    return result;
  }

  public async updateSubscriptions(data: UpdateSubscriptionsDTO) {
    this.storeEntity = 'subscriptions';
    const result = await this.post({ subscriptions: data });
    return result;
  }

  public async emailVerificationInit(
    email: string,
    options?: {
      entities?: { type: string; id: string | number }[];
      verificationUrl?: string;
    }
  ) {
    this.storeEntity = 'verification';
    const result = await this.post({ email, ...options }, '/email/init');
    return result;
  }

  public async emailVerificationProof(uuid: string, hash: string): Promise<VerificationResponseType> {
    this.storeEntity = 'verification';
    const result = await this.post({ hash }, `/${uuid}/verify`);
    return result;
  }

  public async emailVerificationCheck(
    email: string,
    entity: VerificationEntityType,
    entityId: string | number
  ): Promise<VerificationResponseType> {
    this.storeEntity = 'verification';
    const result = await this.post({ email }, `/email/${entity}/${entityId}`);
    return result;
  }

  public async emailVerificationCheckExists(
    email: string,
    entity: VerificationEntityType,
    entityId: string | number
  ): Promise<VerificationExistsType> {
    this.storeEntity = 'verification';
    const result = await this.post({ email }, `/email/${entity}/${entityId}/exists`);
    return result;
  }
}

export const messagesService = new Messages(fetch, API, cache, config);
