import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { generalTransition } from '@brainysoft/lk-custom/variables';
import { greyText, primary, secondary, white } from '@brainysoft/lk-custom/colors';
import Color from 'color';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'block',
    borderRadius: 500,
  },
  item: {
    padding: '.5rem .875rem',
    borderRadius: 500,
    transition: generalTransition,
    display: 'flex',
    alignItems: 'flex-start',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.2)',
    },
    '&.active': {
      background: 'rgba(255, 255, 255, 0.4)',
    },
    '& .itemText': {
      fontSize: '1.075rem',
      color: white,
    },
    '&.offcanvas': {
      padding: '1rem 2rem',
      textAlign: 'center',
      justifyContent: 'center',
      '& .itemText': {
        fontSize: '1.075rem',
        color: white,
      },
    },
    '&.inverted:not(.offcanvas)': {
      '&:hover:not(.active)': {
        background: Color(secondary).fade(0.92).rgb().string(),
        '& .itemText': {
          color: secondary,
        },
      },
      '& .itemText': {
        fontSize: '1.075rem',
        color: primary,
      },
      '&.active': {
        background: Color(primary).fade(0.9).rgb().string(),
      },
    },
  },
  icon: {
    width: '2.5rem',
    height: '2.5rem',
  },
  externalLinkIcon: {
    marginLeft: '.25rem',
    width: '.5rem',
    height: '.5rem',
  },
  active: {
    background: 'rgba(255, 255, 255, 0.3)',
    transition: generalTransition,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.4)',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    itemText: {
      fontSize: '0.875rem',
      marginLeft: '1.1875rem',
      color: greyText,
    },
    item: {
      padding: '0.75rem 1rem',
    },
  },
};

export default css;
