export enum CreditStatusType {
  //Новая
  NEW = 'NEW',
  // Проверка

  CHECK = 'CHECK',

  // Одобрено
  APPROVED = 'APPROVED',

  // Отказано
  DENIED = 'DENIED',

  // Отказ клиента
  CLIENT_REFUSAL = 'CLIENT_REFUSAL',

  // Ожидает
  WAITING = 'WAITING',

  // Технический сбой
  TECH_FAULT = 'TECH_FAULT',

  // Кредит выдан
  ISSUED = 'ISSUED',

  // Кредит закрыт
  CLOSED = 'CLOSED',

  // Кредит продан по цессии
  SOLD = 'SOLD',

  // Кредит просрочен
  DELINQUENCY = 'DELINQUENCY',

  // Проверка документов
  MANUAL_CHECK = 'MANUAL_CHECK',
}
