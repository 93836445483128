import { SCREEN_SIZE } from '@brainysoft/lk-components';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  avatarWrapper: {
    width: '3rem',
    height: '3rem',
    borderRadius: 500,
    overflow: 'hidden',
    '& > img': {
      width: '3rem',
      height: '3rem',
    },
  },
  personalData: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    leftSide: {
      display: 'none',
    },
    rightSide: {
      width: '100%',
      padding: ['1.25rem', '0.9375rem'],
    },
  },
};

export default css;
