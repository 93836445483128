import cache, { cacheService as Storage } from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';
import { IProcessService, ProcessSaveDTO, ProcessSetStepDTO } from '../../interfaces';
import { ProcessType } from '@./shared';

class CheckService extends Entity {
  public async checkInn(rnn: string) {
    this.storeEntity = 'check';
    const result = await this.fetch('', `/${rnn}/inn`);
    return result;
  }

  public async checkEmail(email: string) {
    this.storeEntity = 'check';
    const result = await this.post({ email }, '/email');
    return result;
  }
}

export const checkService = new CheckService(fetch, API, cache, config);
