import { merge } from 'lodash';
import Color from 'color';
import { colors as defaultColors } from '@custom/defaults/theme/Styles';

export type ThemeColorsInterface = {
  white: string;
  black: string;
  primary: string;
  primaryDarken: string;
  primaryLight: string;
  primaryDark: string;
  grey: string;
  greyDark: string;
  greyLight: string;
  greyLighter: string;
  greyText: string;
  greyLightText: string;
  red: string;
  green: string;
  blue: string;
  yellow: string;
  pageBackground: string;
  primaryHover: string;
  primaryBackground: string;
  secondary: string;
  secondaryBackground: string;
  highlightColor: string;
  disabledBackground: string;
  footerBackground?: string;
};

export const mergeThemeColors = (custom: { [k: string]: unknown }): ThemeColorsInterface => {
  const colors = merge(defaultColors, custom);

  return {
    white: colors.white,
    black: colors.black,
    primary: colors.primary,
    primaryDarken: colors.primaryDarken,
    primaryLight: colors.primaryLight,
    primaryDark: colors.primaryDark,
    grey: colors.grey,
    greyDark: colors.greyDark,
    greyLight: colors.greyLight,
    greyLighter: Color(colors.grey).lighten(0.3).rgb().string(),
    greyText: colors.greyText,
    greyLightText: colors.greyLightText,
    red: colors.red,
    green: colors.green,
    blue: colors.blue,
    yellow: colors.yellow,
    pageBackground: colors.pageBackground,
    primaryHover: colors.primaryHover,
    primaryBackground: colors.primaryBackground,

    secondary: colors.secondary,
    secondaryBackground: colors.secondaryBackground,

    highlightColor: colors.highlightColor,
    disabledBackground: colors.disabledBackground,

    footerBackground: Color(colors.primaryDarken).darken(0.1).rgb().string(),
  };
};
