export enum PrintFormType {
  OFERTA = 'oferta', //договор займа
  DATA_PROCESSING = 'dataProcessing', //согласие на обработку пд
  INFORMATION = 'information', //информация о предоставлении займа
  TERMS = 'terms', //условия предоставления займа
  CONSENT_MAILING = 'consentMailing', //согласие на отправку сообщений
  CONSENT = 'consent', //согласие с условиями займа
  PROLONGATION = 'prolongation', //договор пролонгации
  LOAN_APPLICATION = 'loanApplication', //заявка на займ
  OFERTA_INSURANCE = 'ofertaInsurance', //договор страхования займа
  CONSENT_ADVERTISING = 'consentAdvertising', //согласие на получение рекламы

  CONSENT_ASP = 'consentAsp', //соглашение об использовании аналога собственноручной подписи
  CONSENT_CREDIT_HISTORY = 'consentCreditHistory', //соглашение на передачу кредитной истории
  CONSENT_THIRD_PARTY_INTERACTION = 'consentThirdPartyInteraction', //соглашение на передачу информации третьим лицам
  CONSENT_INSURANCE = 'consentInsurance', //соглашение на страхование

  LOAN_APP_INSURANCE = 'loanAppInsurance', //соглашение на страхование (заявка)
  LOAN_APP_ONLINE_RISKS = 'loanAppOnlineRisks', //Уведомление о риске онлайн (заявка)
  RECURRING_PAYMENTS = 'recurringPayments', //соглашение на автоплатеж

  AUTOACCEPT = 'autoaccept', //соглашение на автоакцепт платежа

  PDN_NOTIFICATION = 'pdnNotification', //уведомление о расчете ПДН

  EARLY_REPAYMENT_APPLICATION = 'earlyRepaymentApplication', //Заявление на полное досрочное погашение

  CURRENT_SCHEDULE = 'currentSchedule', // График платежей

  INTEREST_DECLINE = 'interestDecline', //"Уведомление о снижении ставки"
  ADDITIONAL_CONSENT = 'additionalConsent', // "Дополнительное соглашение"

  CONSENT_CARDS = 'consentCards', //Согласие на обработку и хранение указанных карт

  CONSENT_DDO = 'consentDDO', //Заявление о присоединении к договору дистанционного обслуживания

  RISK_PDN_NOTIFICATION = 'riskPdnNotification', //Уведомление о риске предельно допустимой нагрузки
}
