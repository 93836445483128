import Color from 'color';
import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { VARIABLES } from '@brainysoft/lk-custom/variables';

const { fontSizeLarge, generalBorderRadius, inputBorderColor } = VARIABLES;
const { greyText } = THEME_COLORS;

export const css = {
  switchWrapper: {
    display: 'flex',
    '& > .ant-switch': {
      marginTop: '.25rem',
    },
  },
  switchLabel: {
    display: 'flex',
    marginLeft: '1rem',
    flexDirection: 'column',
    justifyContent: 'center',
    lineHeight: 1.25,
    fontSize: '.875rem',
    '& > :last-child': {
      marginBottom: 0,
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    switchWrapper: {},
  },
};

export default css;
