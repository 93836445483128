import '../wdyr';
import App from 'next/app';
import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Loading from '../src/components/Loading';
import {
  clientService,
  consumerService,
  cacheService as Storage,
  settingsService,
  tokenService,
  AppContext,
  authorizedAccess,
  surveysService,
  TEN_MINUTES_STALE,
  dataAutoload,
} from '@brainysoft/lk-components';
import {
  HEADER_INVERTED,
  LOCALE,
  NODE_ENV_PRODUCTION,
  SAVE_COOKIES,
  SAVE_COOKIES_TTL,
  SAVE_COOKIES_MAP,
} from '../src/config';
import { white } from '@brainysoft/lk-custom/colors';
import injectSheet from 'react-jss';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { IPageWithInitialData } from '@brainysoft/lk-components';
import { useSettings } from '../src/queries/useSettings';
import { ONE_HOUR_STALE } from '../src/const';
import { useLegalEntityInfo } from '../src/queries/useLegalEntityInfo';

//internationalization
import i18n from 'i18next';
import moment from 'moment';
import '../i18next.config';
import { getLocale } from '../src/utils/ant-locale';
moment.locale(LOCALE);

//global vendor styles
import 'normalize.css';
import 'noty/lib/noty.css';
import 'noty/lib/themes/mint.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { themeGlobals } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { ConfigProvider } from 'antd';
import { parseCookies, setCookie } from 'nookies';
import { BlinkTitle } from '../src/integrations/BlinkTitle';
import { useSurveys } from '../src/queries/useSurveys';
import { useRouter } from 'next/router';
import { SentrySession } from '../src/components/SentrySession';
import { Jivo } from '../src/integrations/Jivo';
import { INTEGRATIONS } from '../src/config';
import { useBuildInfo } from '../src/queries/useBuildInfo';
import { CAPTCHA } from '../src/components/Captcha/Captcha';
import { CaptchaStoreContext } from '../src/ctx/captcha-context';

const link = INTEGRATIONS['jivo']?.link ?? null;
const name = INTEGRATIONS['jivo']?.name ?? null;
const container = INTEGRATIONS['jivo']?.container ?? null;
const isJivo = !!link && !!name && !!container;

interface IProps {
  pageProps: any;
  Component: any;
  err: any;
}

const appStyles = themeGlobals();

const cache = new Map<string, unknown>();

class MyApp extends App<IProps> {
  public state = {
    isRouteChanging: false,
    loadingKey: '',
    onload: true,
    queryClientRef: { current: null as any },
    consumerUuid: null,
    sessionId: null,
  };

  constructor(props) {
    super(props);
    this.state.queryClientRef = React.createRef<any>();
  }

  public static async getInitialProps({ Component, ctx }) {
    if (!dataAutoload(ctx.pathname)) return { pageProps: {} };

    let pageProps: any = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    const initialData: any = {};
    let info, settings, surveys;
    if (!process.browser) {
      if (cache.has('info') && cache.has('settings')) {
        info = cache.get('info');
        settings = cache.get('settings');
      } else {
        [info, settings, surveys] = await Promise.all([
          consumerService.getLegalEntityInfo(),
          settingsService.data(),
          surveysService.surveys(),
        ]);
      }

      if (info) {
        cache.set('info', info);
        initialData['legalEntityInfo'] = info;
      }

      if (settings) {
        cache.set('settings', settings);
        initialData['settings'] = settings;
      }

      if (surveys) {
        initialData['surveys'] = surveys;
      }
    }

    return { pageProps: { ...pageProps, ...initialData } };
  }

  public async componentDidMount() {
    const { router } = this.props;

    const routeChangeStartHandler = () => {
      this.setState(() => ({
        isRouteChanging: true,
        loadingKey: uuidv4().substr(0, 8),
      }));
    };

    const routeChangeEndHandler = () => {
      this.setState(() => ({
        isRouteChanging: false,
      }));
    };

    router.events.on('routeChangeStart', routeChangeStartHandler);
    router.events.on('routeChangeComplete', routeChangeEndHandler);
    router.events.on('routeChangeError', routeChangeEndHandler);

    SAVE_COOKIES.forEach((cookieName) => {
      if (router.query[cookieName] !== undefined) {
        const value = router.query[cookieName] as string;
        const maxAge = SAVE_COOKIES_TTL * 24 * 60 * 60;
        const path = '/';
        if (SAVE_COOKIES_MAP[cookieName]) {
          setCookie(null, SAVE_COOKIES_MAP[cookieName], value, { maxAge, path });
        }
        setCookie(null, cookieName, value, { maxAge, path });
      }
    });

    const cookies = parseCookies();
    if (authorizedAccess(router.pathname)) {
      clientService
        .getClient()
        .then((clientInfo) => {
          if (clientInfo) {
            this.setState({
              onload: false,
              consumerUuid: cookies['cons'],
              sessionId: cookies['bslk_session'],
            });
          } else {
            throw new Error('NOT_AUTHENTICATED');
          }
        })
        .catch(() => {
          // notify.error(i18n.t('errors:NOT_AUTHENTICATED'));
          console.error('NOT_AUTHENTICATED');
          Storage.clearExceptRegistration();
          tokenService.logout();
          window.location.href = '/auth';
        });
    } else {
      this.setState({
        onload: false,
        consumerUuid: cookies['cons'],
        sessionId: cookies['bslk_session'],
      });
    }
  }

  public render() {
    const { Component, pageProps, err } = this.props;
    if (this.state.onload) {
      return (
        <React.Fragment>
          <Loading isRouteChanging={true} loadingKey={this.state.loadingKey} />
        </React.Fragment>
      );
    }
    const color = HEADER_INVERTED ? undefined : white;

    if (!this.state.queryClientRef.current) {
      this.state.queryClientRef.current = new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: 3000,
          },
          mutations: {
            retry: false,
          },
        },
      });
    }

    return (
      <ConfigProvider locale={getLocale(LOCALE)}>
        <SentrySession
          consumerUuid={this.state.consumerUuid}
          sessionId={this.state.sessionId}
          isLoading={this.state.onload}
        />
        <BlinkTitle />
        <AppContext.Provider>
          <CaptchaStoreContext.Provider>
            <QueryClientProvider client={this.state.queryClientRef.current}>
              <BuildInfoCheck />
              {isJivo && (
                <Jivo
                  jivoName={name}
                  jivoLink={link}
                  jivoContainer={container}
                  urlsRegException={[/^\/auth/, /^\/registration/, /^\/loan-app/, /^\/contracts\/sign\/signDocuments/]}
                />
              )}
              <InitialData {...pageProps}>
                <Loading
                  isRouteChanging={this.state.isRouteChanging}
                  loadingKey={this.state.loadingKey}
                  color={color}
                />
                <Component {...pageProps} err={err} />
                <CAPTCHA />
                {!NODE_ENV_PRODUCTION && <ReactQueryDevtools initialIsOpen={false} />}
              </InitialData>
            </QueryClientProvider>
          </CaptchaStoreContext.Provider>
        </AppContext.Provider>
      </ConfigProvider>
    );
  }
}

export default injectSheet(appStyles)(MyApp);

const InitialData: React.FC<IPageWithInitialData> = (props) => {
  const { pathname } = useRouter();

  const autoloadEnabled = dataAutoload(pathname);

  useSettings({ initialData: props.settings, staleTime: ONE_HOUR_STALE, enabled: autoloadEnabled });
  useLegalEntityInfo({ initialData: props.legalEntityInfo, staleTime: ONE_HOUR_STALE, enabled: autoloadEnabled });
  useSurveys({ initialData: props.surveys, staleTime: TEN_MINUTES_STALE, enabled: autoloadEnabled });

  return <>{props.children}</>;
};

const BuildInfoCheck: React.FC = () => {
  const router = useRouter();
  const currentCommit = process.env.NEXT_PUBLIC_BUILD_INFO_COMMIT;

  const buildInfoQuery = useBuildInfo();
  const actualCommit = buildInfoQuery.data?.COMMIT;

  useEffect(() => {
    if (currentCommit && actualCommit && currentCommit !== actualCommit) {
      console.log('useEffect current commit', currentCommit);
      console.log('useEffect build-info commit', actualCommit);
      console.log('reloading');

      router.reload();
    }
  }, [currentCommit, actualCommit]);

  return <></>;
};
