import { SCREEN_SIZE } from '@brainysoft/lk-components';

export const css = {
  collapse: {
    '&.simple': {
      background: 'transparent',
    },
    '&.paddingless': {
      margin: '0',
      '& .ant-collapse-content': {
        padding: 0,
      },
      '& .ant-collapse-header': {
        padding: 0,
      },
      '& .ant-collapse-content-box': {
        padding: '0',
      },
    },
    '&.headerless': {
      '& .ant-collapse-header': {
        display: 'none',
      },
      '& .ant-collapse-item': {
        border: 0,
      },
    },
    '&.borderless': {
      '& .ant-collapse-item': {
        border: 0,
      },
    },
    '&.stackedArrow': {
      '& .ant-collapse-header': {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& .ant-collapse-arrow': {
          marginLeft: '.75rem',
          position: 'relative',
          top: 'auto',
          left: 'auto',
          right: 'auto',
          transform: 'translateY(0)',
        },
      },
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    collapse: {},
  },
};

export default css;
