import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';
import { CreditT, CreditDetailsT } from '../../interfaces';

class CreditService extends Entity {
  public async all() {
    this.storeEntity = 'credit';
    const result = await this.fetch({}, '/all');
    return result;
  }
  public async getActiveCredit(): Promise<CreditT> {
    this.storeEntity = 'credit';
    const result = await this.fetch({}, '/active');
    return result;
  }
  public async getActiveCreditDetails(): Promise<CreditDetailsT> {
    this.storeEntity = 'credit';
    const result = await this.fetch({}, '/active/details');
    return result;
  }
  public async signOfferChallenge(creditUuid: string) {
    this.storeEntity = 'credit';
    const result = await this.post({}, `/${creditUuid}/interactions/sign-offer/challenge`);
    return result;
  }
  public async signOfferChallengeAnswer(creditUuid: string, answer: string, data: Record<string, unknown> = {}) {
    this.storeEntity = 'credit';
    const result = await this.post({ answer, ...data }, `/${creditUuid}/interactions/sign-offer/challenge/answer`);
    return result;
  }
  public async getUploadPassportData(creditUuid: string) {
    this.storeEntity = 'credit';
    const result = await this.fetch({}, `/${creditUuid}/interactions/upload-passport`);
    return result;
  }
  public async uploadPassport(creditUuid: string, data) {
    this.storeEntity = 'credit';
    const result = await this.post(data, `/${creditUuid}/interactions/upload-passport/upload`, true);
    return result;
  }
  public async uploadPassportSubmit(creditUuid: string) {
    this.storeEntity = 'credit';
    const result = await this.post({}, `/${creditUuid}/interactions/upload-passport`);
    return result;
  }
  public async denyText(creditUuid: string): Promise<{ expirationDate: number | null; link: string }> {
    this.storeEntity = 'credit';
    const result = await this.fetch({}, `/${creditUuid}/deny-text`);
    return result;
  }
  public async getDaysLeftCoolOffExpiration(): Promise<{ days: number | null }> {
    this.storeEntity = 'credit';
    const result = await this.fetch({}, `/permissions/get-days-left-cool-off-expiration`);
    return result;
  }
  public async isRepaymentAvailable(contractId: string | number) {
    this.storeEntity = 'credit';
    const result = await this.post({ contractId }, '/repayments/available');
    return result;
  }
  public async prolongationSettings(contractId: string | number) {
    this.storeEntity = 'credit';
    const result = await this.post({ contractId }, '/prolongation-settings');
    return result;
  }
  public async canCreate() {
    this.storeEntity = 'credit';
    const result = await this.post({}, '/permissions/can-create');
    return result;
  }
}

export const creditService = new CreditService(fetch, API, cache, config);
