import { SCREEN_SIZE } from '@brainysoft/lk-components';

export const css = {
  consentsWrapper: {
    padding: '1rem',
    background: 'rgb(248,248,248)',
  },
  consentsItemWrapper: {
    marginBottom: '1rem',
  },
  consentsItem: {
    display: 'flex',
    '&:not(:last-child)': {
      marginBottom: '.875rem',
    },
  },
  checkbox: {
    marginRight: '1rem',
  },
  text: {
    flex: 1,
    fontSize: '.875rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
