export const notify = {
  '.errorWrapper': {},
  '.errorText': {},
  '.errorActivityLabel': {
    marginTop: 4,
    textAlign: 'right',
    fontSize: '.75em',
    opacity: '.5',
  },
};
