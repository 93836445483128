import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';

class CreditProductsService extends Entity {
  public async data() {
    this.storeEntity = 'creditProducts';
    const result = await this.fetch();
    return result;
  }
}

export const creditProductService = new CreditProductsService(fetch, API, cache, config);
