import { useQuery } from 'react-query';
import { REFRESH_DATA_INTERVAL } from '../config';
import { UseQueryResult } from 'react-query/types/react/types';
import { BuildInfoT, settingsService } from '@brainysoft/lk-components';

export const useBuildInfo: () => UseQueryResult<BuildInfoT> = () => {
  return useQuery(
    ['buildInfo'],
    () => {
      return settingsService.buildInfo() as Promise<BuildInfoT>;
    },
    {
      refetchInterval: REFRESH_DATA_INTERVAL,
    }
  );
};
