import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { white, grey, primary } from '@brainysoft/lk-custom/colors';

export const css = {
  dimmer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: white,
    opacity: 0.95,
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dimmerTransparent: {
    background: 'transparent',
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    '&.white $spot': {
      background: white,
    },
    '&.grey $spot': {
      background: grey,
    },
    '&.inline': {
      display: 'inline-flex',
    },
  },
  spinnerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spot: {
    borderRadius: '100%',
    background: primary,
    width: 10,
    height: 10,
    animationName: 'pulse',
    animationDuration: '1s',
    animationTimingFunction: 'cubic-bezier(0.2, 0.68, 0.18, 1.08)',
    animationDirection: 'normal',
    animationPlayState: 'running',
    animationFillMode: 'both',
    animationIterationCount: 'infinite',
  },

  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
