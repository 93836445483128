import { SCREEN_SIZE } from '@brainysoft/lk-components';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  profileWrapper: {
    textAlign: 'left',
  },
  clientWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  avatarWrapper: {
    marginRight: '1rem !important',
  },
  clientFio: {
    flex: 1,
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
