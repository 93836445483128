import { TENANT_DOMAIN } from '@brainysoft/lk-custom/config';

export class Store {
  public storage: any = {};
  private prefix: string | undefined;

  constructor() {
    if (this.isBrowser()) {
      this.storage = window.localStorage;
      this.prefix = TENANT_DOMAIN;
    }
  }

  private isBrowser() {
    return typeof window !== 'undefined';
  }
  private isServer() {
    return typeof window === 'undefined';
  }
  private logError(error: any) {
    if (this.isBrowser()) {
      console.log(error);
    }
  }
  private checkIsBrowser() {
    if (this.isServer()) {
      throw new Error('localStorage not found');
    }
  }

  private getKey(key: string) {
    return `${this.prefix}:${key}`;
  }

  public setItem(key: any, data: any) {
    return new Promise((resolve, reject) => {
      try {
        this.checkIsBrowser();
        this.storage.setItem(this.getKey(key), data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    }).catch((error) => {
      this.logError(error);
    });
  }

  public getItem(key: any) {
    return new Promise((resolve, reject) => {
      try {
        this.checkIsBrowser();
        const data = this.storage.getItem(this.getKey(key));
        resolve(data);
      } catch (error) {
        reject(error);
      }
    }).catch((error) => {
      this.logError(error);
    });
  }

  public removeItem(key: any) {
    return new Promise((resolve, reject) => {
      try {
        this.checkIsBrowser();
        this.storage.removeItem(this.getKey(key));
        resolve(true);
      } catch (error) {
        reject(error);
      }
    }).catch((error) => {
      this.logError(error);
    });
  }

  public clear() {
    return new Promise((resolve, reject) => {
      try {
        this.checkIsBrowser();
        this.storage.clear();
        resolve(true);
      } catch (error) {
        reject(error);
      }
    }).catch((error) => {
      this.logError(error);
    });
  }

  public getAllKeys() {
    return new Promise((resolve, reject) => {
      try {
        this.checkIsBrowser();
        const keys: string[] = [];
        for (const key in this.storage) {
          keys.push(key);
        }
        resolve(keys);
      } catch (error) {
        reject(error);
      }
    }).catch((error) => {
      this.logError(error);
    });
  }

  public clearExceptRegistration() {
    return new Promise<void>((resolve, reject) => {
      try {
        this.checkIsBrowser();
        const doClear = async () => {
          const uuid = await this.getItem('process:registration:uuid');
          const session = await this.getItem('process:registration:session');
          await this.clear();
          if (uuid) await this.setItem('process:registration:uuid', uuid);
          if (session) await this.setItem('process:registration:session', session);
          resolve();
        };
        doClear();
      } catch (error) {
        reject(error);
      }
    }).catch((error) => {
      this.logError(error);
    });
  }
}

export default new Store();

export const cacheService = new Store();
