import { SCREEN_SIZE } from '@brainysoft/lk-components';

export const css = {
  landing: {
    padding: '1rem',
  },
  externalWidget: {
    '& .ant-input-number': {
      fontSize: '3rem !important',
    },
  },
  pageTitle: {
    fontSize: '1.5rem',
    marginTop: '1rem',
    textAlign: 'center',
    lineHeight: 1.1,
    marginBottom: '2rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
