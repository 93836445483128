import crossFetch from 'cross-fetch';
import tokenService from './token';

export type FetchWithTokenT = (
  url: string,
  options: { headers?: Record<string, string> },
  ctx: any
) => Promise<Response>;

const fetchWithToken: FetchWithTokenT = async (url, options = {}, ctx = null) => {
  const fetch: (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response> = crossFetch;

  const token = tokenService.get(ctx);
  options.headers = {
    ...options.headers,
    Accept: 'application/vnd.api+json',
    Authorization: `Bearer ${token}`,
  };

  return fetch(url, options);
};

export default fetchWithToken;
