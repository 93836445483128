import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';
import { BuildInfoT } from '../../interfaces';

class SettingsService extends Entity {
  public async data() {
    this.storeEntity = 'settings';
    const result = await this.fetch();
    return result;
  }

  public async buildInfo(): Promise<BuildInfoT> {
    this.storeEntity = 'buildInfo';
    const result = await this.fetch();
    return result;
  }
}

export const settingsService = new SettingsService(fetch, API, cache, config);
