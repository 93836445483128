import i18n from 'i18next';

export enum MatrialStatusType {
  /** Холостой/Незамужняя */
  SINGLE = 101151,

  /** Женат/Замужем */
  MARRIED = 101152,

  /** Разведен/Разведена */
  DIVORCED = 101153,

  /** Вдовец/Вдова */
  WIDOW = 101154,

  /** Гражданский брак */
  CIVIL_MARRIAGE = 101155,
}

export const MatrialStatusTypeToOptions = () => {
  const _enumArray = Object.values(MatrialStatusType);
  const arrayLength = _enumArray.length / 2;

  const options: any[] = [];

  for (let i = 0; i < arrayLength; i++) {
    options.push({
      id: _enumArray[arrayLength + i],
      name: i18n.t(`enums:MatrialStatusType.${_enumArray[i]}`),
    });
  }
  return options;
};
