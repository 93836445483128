import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';

class FundTransactions extends Entity {
  public async getFundTransactionsList() {
    this.storeEntity = 'fundTransactions';
    const result = await this.fetch();
    return result;
  }

  public async getContractFundTransactions(id: number) {
    this.storeEntity = 'contract';
    const result = await this.fetch('', `/${id}/fund-transactions`);
    return result;
  }
}

export const fundTransactionService = new FundTransactions(fetch, API, cache, config);
