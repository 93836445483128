import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { maxWidth } from '@brainysoft/lk-custom/variables';
import { primary } from '@brainysoft/lk-custom/colors';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  indexPage: {
    background: '#e6e7e8',
    maxWidth: maxWidth,
    margin: 'auto',
    flex: 1,
    width: '100%',
  },
  leftSide: {
    display: 'inline-block',
    width: '25%',
    padding: '1.875rem 1.5rem',
    boxSizing: 'border-box',
    verticalAlign: 'top',
  },
  rightSide: {
    display: 'inline-block',
    width: '75%',
    padding: '1.875rem 1.5rem',
    boxSizing: 'border-box',
    verticalAlign: 'top',
  },
  greeting: {
    fontSize: '2.25rem',
    fontWeight: 'bold',
    color: primary,
    margin: [0, 0, '1.875rem'],
  },
  paymentsWrap: {
    display: 'inline-block',
    width: '49%',
    marginRight: '2%',
  },
  personalDataWrap: {
    display: 'inline-block',
    width: '49%',
  },
  tableWrap: {
    borderRadius: '0.375rem',
    background: '#ffffff',
    boxSizing: 'border-box',
    padding: '0.9375rem',
    textAlign: 'center',
    marginTop: '1rem',
  },
  tableTitle: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: primary,
    margin: [0, 0, '0.9375rem'],
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  personalInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    // leftSide: {
    //     display: 'none'
    // },
    // rightSide: {
    //     width: '100%',
    //     padding: [20, 15]
    // },
    // greeting: {
    //     margin: '0 0 15px',
    //     fontSize: 30
    // }
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    leftSide: {
      display: 'none',
    },
    rightSide: {
      width: '100%',
      padding: ['1.25rem', '0.9375rem'],
    },
    paymentsWrap: {
      width: '100%',
      marginRight: 0,
      marginBottom: '0.625rem',
    },
    personalDataWrap: {
      width: '100%',
    },
    tableWrap: {
      marginTop: '0.625rem',
    },
    greeting: {
      fontSize: '1.125rem',
      margin: [0, 0, '0.9375rem'],
    },
    tableTitle: {
      fontSize: '0.75rem',
      margin: [0, 0, '0.5625rem'],
    },
    personalInfo: {
      flexDirection: 'column',
    },
  },
};

export default css;
