import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';
import { ExternalAuthProviders } from '../../enums';

type ExternalAuthInitRequestT = {
  provider: ExternalAuthProviders;
  returnUrl: string;
};

type ExternalAuthInitResponseT = {
  redirectUrl: string;
};

export type ExternalAuthResultT = {
  status: string;
  clientId: number | null;
  access_token?: string;
};

class Auth extends Entity {
  public async signOut() {
    this.storeEntity = 'signOut';
    const result = await this.fetch();
    return result;
  }

  public async isAuthenticated() {
    this.storeEntity = 'isAuthenticated';
    const result = await this.fetch();
    return result;
  }

  public async initExternalAuth(data: ExternalAuthInitRequestT): Promise<ExternalAuthInitResponseT> {
    this.storeEntity = 'externalAuth';
    const result = await this.post(data, '/init');
    return result;
  }

  public async externalAuthResult(): Promise<ExternalAuthResultT> {
    this.storeEntity = 'externalAuth';
    const result = await this.fetch('', `/result`);
    return result;
  }

  public async externalAuthRegistrationResult(registrationProcessUuid: string): Promise<ExternalAuthResultT> {
    this.storeEntity = 'externalAuth';
    const result = await this.fetch('', `/registration/${registrationProcessUuid}/result`);
    return result;
  }
}

export const authService = new Auth(fetch, API, cache, config);
