import { VARIABLES } from '@brainysoft/lk-custom/variables';
import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import Color from 'color';

const { fontSizeLarge, generalBorderRadius, inputBorderColor } = VARIABLES;
const { greyText } = THEME_COLORS;

export const css = {
  input: {
    borderRadius: generalBorderRadius,
    width: '100%',
    color: greyText,
    padding: '0 1rem 0 0 !important',
    '& .ant-input': {
      padding: '.75rem 2.25rem .75rem 1.25rem',
      height: 'auto',
      color: greyText,
    },
    '& .ant-calendar-input': {
      color: greyText,
    },
    '& .ant-picker-input > input': {
      fontSize: `${fontSizeLarge} !important`,
      padding: '.75rem 1.25rem',
      height: 'auto',
      lineHeight: '24px !important',
      color: greyText,
    },
    '&:not(.ant-picker-disabled):hover': {
      borderColor: inputBorderColor,
    },
    '&:not(.ant-picker-disabled):focus, &.ant-picker-focused': {
      borderColor: inputBorderColor,
      boxShadow: `0 0 0 2px ${Color(inputBorderColor).fade(0.8).rgb().string()} !important`,
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    input: {},
  },
};

export default css;
