import { helpers } from './helpers';
import { global } from './global';
import { antdPatches } from './antdPatches';
import { dadata } from './dadata';
import { notify } from './notify';
import { animation } from './animation';
import { fonts } from './fonts';

export const APP_STYLES = {
  '@global': {
    ...fonts,
    ...animation,
    ...global,
    ...helpers,
    ...antdPatches,
    ...dadata,
    ...notify,
  },
};
