import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { generalBorder, generalBorderRadius, generalTransition } from '@brainysoft/lk-custom/variables';
import { greyLightText, greyText, white } from '@brainysoft/lk-custom/colors';

export const css = {
  phoneInput: {
    '& .react-tel-input': {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    '& .react-tel-input input[type=tel]': {
      width: '100%',
      border: generalBorder,
      fontSize: '1rem',
      padding: '1rem 1.25rem',
      paddingLeft: '0.5rem',
      boxSizing: 'border-box',
      height: 'auto',
      borderLeftWidth: 0,
      borderRadius: `0 ${generalBorderRadius} ${generalBorderRadius} 0`,
      transition: generalTransition,
      '&::placeholder': {
        color: greyLightText,
      },
      '&:-internal-autofill-selected': {
        background: `${white} !important`,
      },
    },
    '& .react-tel-input .flag-dropdown': {
      position: 'static',
      border: generalBorder,
      background: white,
      borderRight: 0,
      borderRadius: `${generalBorderRadius} 0 0 ${generalBorderRadius}`,
      '&:hover': {
        background: 'transparent',
      },
    },
    '& .react-tel-input .flag-dropdown .country-list': {
      width: '100%',
      boxShadow: '0px 3px 6px rgba(0,0,0,0.25)',
      marginTop: '1px',
    },
  },
  label: {
    display: 'block',
    fontSize: '0.8125rem',
    color: greyText,
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    button: {
      fontSize: '0.875rem',
      padding: ['0.3125rem', '1.25rem'],
    },
  },
};

export default css;
