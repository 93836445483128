import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';
// eslint-disable-next-line no-unused-vars

class CreditCalc extends Entity {
  // public async calcSchedule(data) {
  //     this.storeEntity = 'creditCalc';
  //     const result = await this.post(data,'calc-schedule');
  //     return result;
  // }

  public async calcPlanDates(data) {
    this.storeEntity = 'creditCalc';
    const result = await this.post(data, '/calc-plan-dates');
    return result;
  }
}

export const creditCalcService = new CreditCalc(fetch, API, cache, config);
