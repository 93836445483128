import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';

class DictionaryService extends Entity {
  public async getOne(code) {
    this.storeEntity = 'dictionary';
    const result = await this.fetch('', `/${code}`);
    return result;
  }
}

export const dictionaryService = new DictionaryService(fetch, API, cache, config);
