import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';

class JivoService extends Entity {
  public async isJivo() {
    this.storeEntity = 'jivo';
    const result: boolean = await this.fetch('', '/is-jivo');
    return result;
  }
}

export const jivoService = new JivoService(fetch, API, cache, config);
