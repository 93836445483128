import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { generalBorder } from '@brainysoft/lk-custom/variables';
import { greyText, red } from '@brainysoft/lk-custom/colors';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  row: {
    borderTop: generalBorder,
    padding: ['0.75rem', 0],
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '&:first-child': {
      borderTop: 0,
    },
  },
  caption: {
    fontSize: '1rem',
    color: '#878787',
    display: 'inline-block',
    width: '50%',
  },
  summ: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#131413',
    marginLeft: '1rem',
  },
  red: {
    color: `${red} !important`,
  },
  blue: {
    color: '#207ad7',
  },
  block: {
    marginBottom: '2.5rem',
  },
  smallText: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#000000',
    marginLeft: '1rem',
  },
  number: {
    color: '#207ad7',
    textDecoration: 'underline',
    fontSize: '1rem',
    marginLeft: '1rem',
  },
  details: {
    fontSize: '1rem',
    color: '#0078dc',
    display: 'inline-block',
    paddingLeft: 31,
    position: 'relative',
    '&::before': {
      content: '',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: -3,
      width: '1.5rem',
      height: '1.75rem',
      background: 'url(/img/document.svg) no-repeat',
      backgroundSize: 'contain',
    },
  },
  list: {
    paddingLeft: '4.375rem',
    display: 'none',
    '& $caption': {
      fontWeight: 'normal',
    },
    '& $row': {
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
  openListButton: {
    border: 'none',
    background: 'transparent',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    fontSize: '0.9375rem',
    outline: 'none',
    marginLeft: '2.8125rem',
  },
  visible: {
    display: 'block',
  },
  withList: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    caption: {
      width: '50%',
      fontSize: '0.8125rem',
    },
    summ: {
      textAlign: 'right',
      display: 'inline-block',
      width: 'calc(50% - 1rem)',
      fontSize: '1.125rem',
    },
    number: {
      textAlign: 'right',
      display: 'inline-block',
      width: 'calc(50% - 1rem)',
      fontSize: '0.875rem',
    },
    smallText: {
      textAlign: 'right',
      display: 'inline-block',
      width: 'calc(50% - 1rem)',
      fontSize: '0.875rem',
    },
    details: {
      fontSize: '0.875rem',
      '&::before': {
        height: '1.5rem',
        width: '1.25rem',
      },
    },
    row: {
      padding: ['0.25rem', '0.3125rem'],
    },
    block: {
      marginBottom: '1.875rem',
    },
    list: {
      paddingLeft: '1.875rem',
    },
    withList: {
      '& span': {
        verticalAlign: 'middle',
      },
    },
    openListButton: {
      marginLeft: '1.25rem',
    },
  },
};

export default css;
