import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';
import { PhotoType } from '../../enums';

class FileStorage extends Entity {
  public async ocrResult(photoType: PhotoType, ocrId: number) {
    this.storeEntity = 'file';
    const result = await this.fetch('', `ocr/${photoType}/${ocrId}`);
    return result;
  }
}

export const fileService = new FileStorage(fetch, API, cache, config);
