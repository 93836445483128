import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { black, greyText, red, green, white, greyLightText, greyLight } from '@brainysoft/lk-custom/colors';
import { generalBorderRadius } from '@brainysoft/lk-custom/variables';

export const css = {
  processGeneralWrapper: {
    width: '100%',
    maxWidth: '1000px',
    margin: '0 auto',
    '& [class^="processWrapper"].registration': {
      background: white,
      borderRadius: generalBorderRadius,
      boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.05)',
      padding: '2rem',
    },
  },
  codeApprovalWrapper: {
    margin: '1rem 0',
  },
  pageTitle: {
    color: black,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    margin: [0, 0, '1rem'],
    textAlign: 'center',
  },
  pageSubTitle: {
    lineHeight: 1.15,
    fontSize: '0.875rem',
    margin: '0 auto 1.5rem',
    textAlign: 'center',
    color: greyText,
    marginBottom: '1rem',
  },
  pageWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    margin: '2rem auto',
  },
  content: {
    marginBottom: '2rem',
  },
  contentWrapper: {
    borderRadius: generalBorderRadius,
    boxShadow: 'none',
    background: white,
    margin: 'auto',
    padding: 0,
    boxSizing: 'border-box',
    minWidth: '850px',
    minHeight: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
  },
  processWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  processContent: {
    flex: '1 1 100%',
  },
  processActions: {
    flex: 0,
    display: 'flex',
    marginTop: '2rem',
    justifyContent: 'flex-end',
  },

  column: {
    display: 'flex !important',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '2rem !important',
  },
  header: {
    textAlign: 'center',
    marginBottom: '1.5rem',
  },
  photoInfo: {
    fontSize: '.875em',
    lineHeight: 1.2,
    '& h3': {
      marginTop: 0,
    },
  },
  orderedList: {
    paddingLeft: '1rem',
  },
  disclaimerDescription: {
    textAlign: 'center',
    fontSize: '.875em',
    lineHeight: 1.2,
  },
  passportImgWrapper: {
    position: 'relative',
    padding: '0 1rem',
    '& img': {
      width: '100%',
    },
  },
  statusIcon: {
    position: 'absolute',
    top: '0',
    right: '1rem',
    transform: 'translate(50%,-40%)',
    borderRadius: '500px',
  },

  registrationFormPassport: {
    '& .registrationFormPassportTip': {
      fontSize: '.875rem',
      color: greyText,
      marginTop: '1.5rem',
      lineHeight: 1.3,
      marginBottom: '.75rem',
    },
    '& .registrationFormPassportTipIconRight , & .registrationFormPassportTipIconWrong': {
      width: '100%',
      background: 'rgb(250, 250, 250)',
      height: '100px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '.5rem',
      borderRadius: generalBorderRadius,
      border: '1px dashed rgb(217, 217, 217)',
      position: 'relative',
      '& .anticon': {
        position: 'absolute',
        bottom: '-30px',
        right: '0',
        fontSize: '2rem',
      },
    },
    '& .registrationFormPassportTipIconRight': {
      '& > img': {
        height: '100%',
      },
      '& .anticon': {
        color: green,
      },
    },
    '& .registrationFormPassportTipIconWrong': {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      '& > img': {
        height: '50%',
        width: '100%',
      },
      '& .anticon': {
        color: red,
      },
    },
  },
  lead: {
    width: 300,
    background: white,
    borderRadius: generalBorderRadius,
    boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.05)',
    padding: '1rem',
    marginRight: '2rem',
    textAlign: 'center',
    marginBottom: '1rem',
  },
  leadHeader: {
    marginTop: '0 !important',
    '&:before, &:after': {
      color: greyLight,
    },
  },
  leadAmount: {
    fontSize: '1.25em',
    fontWeight: 'bold',
  },
  introProcessWrapper: {
    margin: '0 auto',
    padding: '1rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    background: white,
    borderRadius: generalBorderRadius,
    boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.05)',
    '&.registration': {
      padding: '1rem',
      width: '100%',
    },
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .ant-form-item': {
      marginBottom: '0 !important',
    },
    marginBottom: '.5rem',
  },
  identificationWrapper: {
    fontSize: '.875rem',
    display: 'flex',
    flexDirection: 'column',
    '& button': {
      margin: '1rem auto',
      display: 'flex',
      alignItems: 'center',
      '& .custom-icon': {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '.75rem',
      },
    },
  },
  identificationMessage: {
    borderRadius: '1rem',
    margin: '1rem auto',
    padding: '1rem',
    background: 'rgb(242,252,244)',
    border: '1px solid rgb(0,100,15)',
    color: 'rgb(0,100,15)',
    display: 'flex',
    justifyContent: 'flexStart',
    alignItems: 'center',
    '& i.custom-icon': {
      display: 'block',
      marginRight: '1rem',
      '& img': {
        height: '2.5rem',
      },
    },
  },
  success: {
    width: '100%',
    textAlign: 'center',
    padding: '1rem 2rem',
    '& *': {
      textAlign: 'center',
    },
    '& h2': {
      marginTop: 0,
    },
  },
  phoneMessage: {
    fontSize: '.875rem',
    '& .ant-btn': {
      fontSize: '.875rem',
      padding: 0,
    },
  },
  antdModal: {
    '& .ant-modal-title .title': {
      margin: 0,
    },
    '& .ant-modal-body': {
      fontSize: '0.9125rem',
    },
  },
  skeleton: {
    '& .ant-skeleton-content .ant-skeleton-paragraph > li': {
      height: '1.25rem',
      marginBottom: '1.5rem',
    },
  },
  checkboxLabel: {
    height: 'auto',
    minHeight: '2rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: '.875rem',
  },
  disclaimer: {
    color: greyLightText,
    fontSize: '.75rem',
    marginBottom: '1rem',
  },
  passportDisclaimer: {
    marginBottom: '1rem',
    fontWeight: 'bold',
    fontSize: '.9125rem',
  },
  collapse: {
    marginBottom: '2rem !important',
  },
  warning: {
    color: red,
  },
  fioDescriptor: {
    fontSize: '.75rem',
    color: greyLightText,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2rem',
    '& .ant-btn': {
      fontSize: '.75rem',
    },
    '& .fioData.error': {
      color: red,
    },
  },
  uploaderColumn: {
    '& .ant-form-item-label > label': {
      minHeight: '32px !important',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    contentWrapper: {
      width: '90vw',
      minWidth: 'auto',
    },
    uploaderColumn: {
      '& .ant-form-item-label > label': {
        minHeight: 'auto',
      },
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    processGeneralWrapper: {
      '& [class^="processWrapper"].registration': {
        boxShadow: 'none !important',
        padding: 0,
      },
    },
    content: {
      padding: 0,
    },
    contentWrapper: {
      padding: 0,
      minWidth: 'auto',
      minHeight: 'auto',
      width: 'auto',
    },
    pageWrap: {
      margin: '1rem auto',
    },
    checkboxLabel: {
      height: 'auto',
      marginBottom: '1rem',
    },
  },
  [`@media (min-width: ${SCREEN_SIZE.WIDESCREEN}px)`]: {
    processGeneralWrapper: {
      maxWidth: 'none',
    },
  },
};

export default css;
