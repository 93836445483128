import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';
import { PrintFormContextType, PrintFormType } from '../../enums';

class PrintFormService extends Entity {
  public async getDownloadLink(context: PrintFormContextType, printFormType: PrintFormType, params: any) {
    this.storeEntity = 'printForm';
    const result = await this.post(params, `/getDownloadLink/${context}/${printFormType}`);
    return result;
  }

  public async getDownloadLinkUnregistered(context: PrintFormContextType, printFormType: PrintFormType, params: any) {
    this.storeEntity = 'printForm';
    const result = await this.post(params, `/getDownloadLink/${context}/${printFormType}/unregistered`);
    return result;
  }
}

export const printFormService = new PrintFormService(fetch, API, cache, config);
