import { useQuery } from 'react-query';
// eslint-disable-next-line no-unused-vars
import { UseQueryResult } from 'react-query/types/react/types';
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import { QueryClient } from 'react-query/core';
import { ONE_HOUR_STALE } from '../const';
import { settingsService } from '@brainysoft/lk-components';

export const useSettings: (options?: object) => UseQueryResult<any[]> = (options = { staleTime: ONE_HOUR_STALE }) => {
  return useQuery(
    ['settings'],
    () => {
      return settingsService.data() as Promise<any[]>;
    },
    options
  );
};

export const refreshSettings: (queryClient: QueryClient) => void = (queryClient) => {
  queryClient.invalidateQueries(['settings']);
};
