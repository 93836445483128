import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { generalBorder, generalBorderRadius, generalTransition } from '@brainysoft/lk-custom/variables';
import { primary } from '@brainysoft/lk-custom/colors';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  subdivision: {
    borderTop: generalBorder,
    borderRadius: generalBorderRadius,
    transition: generalTransition,
    padding: '1.5rem 0',
    marginBottom: '.75rem',
    '&:last-child': {
      borderBottom: generalBorder,
    },
  },
  title: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBottom: '.75rem',
    color: primary,
  },
  address: {
    marginBottom: '.375rem',
    fontSize: '.875em',
    '& > .anticon': {
      marginRight: '.5rem',
    },
  },
  phone: {
    fontSize: '.875em',
    '& > .anticon': {
      marginRight: '.5rem',
    },
  },
  icon: {
    marginRight: '.75rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    card: {
      fontSize: '0.875rem',
      padding: ['0.3125rem', '1.25rem'],
    },
  },
};

export default css;
