import { SCREEN_SIZE } from '@brainysoft/lk-components';

export const css = {
  form: {
    '& .fields': {
      display: 'flex',
      flexWrap: 'nowrap',

      '&:not(.actions) > *': {
        width: '100%',
      },

      '& .field-wrapper': {
        flex: '1 1 auto',
        margin: '0 .5rem',
        '&:first-child': {
          marginLeft: '2px',
        },
        '&:last-child': {
          marginRight: '2px',
        },
      },
    },
    '& .fields.actions': {
      '&.left': {
        justifyContent: 'flex-start',
      },
      '&.center': {
        justifyContent: 'center',
      },
      '&.right': {
        justifyContent: 'flex-end',
      },
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    form: {},
  },
};

export default css;
