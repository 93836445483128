import { VARIABLES } from '@brainysoft/lk-custom/variables';
import { THEME_COLORS } from '@brainysoft/lk-custom/colors';

export const dadata = {
  '.react-dadata__container': {
    position: 'relative',
    fontFamily: 'sans-serif',
  },

  '.react-dadata__input': {
    display: 'block',
    boxSizing: 'border-box',
    // height: 38,
    // border: '1px solid #ccc',
    // borderRadius: 4,
    width: '100%',
    // fontSize: '16px',
    // padding: '0 10px',
    outline: 'none',
  },

  '.react-dadata__input.react-dadata__input-clearable': {
    paddingRight: 30,
  },

  '.react-dadata__input:focus': {
    borderColor: THEME_COLORS.primary,
  },

  '.react-dadata__suggestions': {
    position: 'absolute',
    zIndex: 10,
    backgroundColor: THEME_COLORS.white,
    boxShadow: '0 1px 6px 3px rgba(0, 0, 0, .1)',
    borderRadius: VARIABLES.generalBorderRadius,
    overflow: 'hidden',
    top: '100%',
    left: 0,
    right: 0,
  },

  '.react-dadata__suggestion-note': {
    fontSize: 14,
    lineHeight: 18,
    color: '#828282',
    padding: '5px 10px',
    display: 'flex',
  },

  '.react-dadata__suggestion-note span': {
    display: 'block',
    lineHeight: 16,
  },

  '.react-dadata__suggestion-note span.suggestion-note_arrow': {
    display: 'flex',
    alignItems: 'center',
    margin: '0 3px',
  },

  '.react-dadata__suggestion': {
    fontSize: 15,
    padding: '7px 10px',
    cursor: 'pointer',
  },

  '.react-dadata__suggestion-info': {
    fontSize: 14,
    color: '#777777',
  },

  '.react-dadata__suggestion--current, .react-dadata__suggestion.react-dadata__suggestion--current:hover': {
    backgroundColor: 'rgba(65, 131, 196, 0.2)',
  },

  '.react-dadata__suggestion:hover': {
    backgroundColor: 'rgba(65, 131, 196, 0.1)',
  },

  '.react-dadata mark': {
    background: 'none',
  },

  '.react-dadata--highlighted': {
    color: '#4183c4',
  },

  '.react-dadata__input-suffix': {
    right: 12,
    position: 'absolute',
    top: '50%',
    zIndex: 2,
    color: 'rgba(0, 0, 0, 0.65)',
    lineHeight: 0,
    '-webkit-transform': 'translateY(-50%)',
    '-ms-transform': 'translateY(-50%)',
    transform: 'translateY(-50%)',
  },

  '.react-dadata__icon': {
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'top',
    '-webkit-transition': 'color .3s',
    transition: 'color .3s',
    width: 16,
    height: 16,
    opacity: 0.3,
    backgroundColor: '#cccccc',
    borderRadius: '50%',
    cursor: 'pointer',
  },

  '.react-dadata__icon:hover': {
    opacity: 1,
  },

  '.react-dadata__icon.react-dadata__icon-clear:before, .react-dadata__icon.react-dadata__icon-clear:after': {
    position: 'absolute',
    top: 4,
    left: 7,
    content: ' ',
    height: 8,
    width: 1,
    backgroundColor: '#ffffff',
  },

  '.react-dadata__icon.react-dadata__icon-clear:before': {
    transform: 'rotate(45deg)',
  },

  '.react-dadata__icon.react-dadata__icon-clear:after': {
    transform: 'rotate(-45deg)',
  },

  '.react-dadata__keyboard-arrows-up-and-down': {
    height: '10%',
  },

  '.react-dadata__keyboard-arrows-right': {
    height: '10%',
  },

  '.react-dadata__custom-action': {
    padding: 0,
  },

  '.react-dadata__custom-action > *': {
    display: 'block',
    height: '100%',
    padding: '7px 10px',
    width: '100%',
  },

  '.actions-delimiter': {
    margin: 0,
    borderTop: '1px solid #eeeeee',
    borderLeft: 'none',
    borderRight: 'none',
  },
};
