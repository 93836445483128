import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { VARIABLES } from '@brainysoft/lk-custom/variables';
import Color from 'color';

const { fontFamily, fontSizeLarge, generalBorder, generalBorderRadius, generalTransition, inputBorderColor } =
  VARIABLES;
const { disabledBackground, greyLightText, greyText, primary, white } = THEME_COLORS;

export const css = {
  phoneInput: {
    '& .react-tel-input': {},
    '& .react-tel-input input[type=tel]': {
      width: '100%',
      color: greyText,
      border: generalBorder,
      fontSize: fontSizeLarge,
      padding: '.75rem 1.25rem',
      paddingLeft: '3rem',
      boxSizing: 'border-box',
      height: 'auto',
      borderRadius: generalBorderRadius,
      transition: generalTransition,
      lineHeight: '24px',
      fontFamily: fontFamily,
      '&::placeholder': {
        fontFamily: fontFamily,
        color: greyLightText,
      },
      '&:-internal-autofill-selected': {
        background: `${white} !important`,
      },
      '&:focus': {
        outline: 0,
        borderColor: inputBorderColor,
        boxShadow: `0 0 0 2px ${Color(inputBorderColor).rgb().fade(0.8).string()} !important`,
      },
      '&:not(.disabled)': {
        '&:hover, &:active': {
          borderColor: inputBorderColor,
        },
        '&:focus': {
          borderColor: primary,
          boxShadow: `0 0 0 2px ${Color(inputBorderColor).rgb().fade(0.8).string()}`,
        },
      },
    },
    '& .react-tel-input .flag-dropdown': {
      border: generalBorder,
      borderRight: 0,
      borderColor: 'transparent',
      background: 'transparent !important',
      '& .selected-flag': {
        borderColor: 'transparent',
        background: 'transparent !important',
      },
    },
    '& .react-tel-input .flag-dropdown .country-list': {
      width: '320px',
      boxShadow: '0px 3px 6px rgba(0,0,0,0.25)',
      marginTop: '1px',
    },
    '& .react-tel-input .flag-dropdown .country-list .country': {
      lineHeight: 1.2,
    },
    formItemWrapper: {
      display: 'flex',
    },
  },
  label: {
    display: 'block',
    fontSize: '0.8125rem',
    color: greyText,
  },
  phoneInputStatic: {
    width: '100%',
    border: generalBorder,
    fontSize: '1rem',
    padding: '.75rem 1.25rem',
    paddingLeft: '1.25rem',
    boxSizing: 'border-box',
    height: 'auto',
    borderRadius: generalBorderRadius,
    transition: generalTransition,
    lineHeight: '1.5rem',
    background: disabledBackground,
    borderColor: '#dcdcde',
    cursor: 'not-allowed',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    button: {
      fontSize: '0.875rem',
      padding: ['0.3125rem', '1.25rem'],
    },
    phoneInput: {
      '& .react-tel-input .flag-dropdown .country-list': {
        width: '240px',
      },
    },
  },
};

export default css;
