import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { primary } from '@brainysoft/lk-custom/colors';
import {
  logo,
  logoAuth,
  logoFooter,
  logoInverted,
  topBarHeight,
  topBarHeightMobile,
  logoRegistration,
} from '../../../variables';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  logoWrapper: {
    cursor: 'pointer',
    position: 'relative',
    width: '100%',
    height: `calc(${topBarHeight} - 2rem)`,
    maxWidth: '13rem',
    marginRight: '2rem',
  },
  logo: {
    padding: '.75rem 1.25rem',
    border: 0,
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: 'bold',
    color: primary,
    borderRadius: '0.625rem',
    lineHeight: '1',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  logoImg: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left center',
    backgroundSize: 'contain',
    '&.inverted': {
      backgroundImage: `url(${logoInverted})`,
    },
    '&.registration': {
      backgroundImage: `url(${logoRegistration})`,
    },
    '&.auth': {
      backgroundImage: `url(${logoAuth})`,
    },
    '&.footer': {
      backgroundPosition: 'left top',
      backgroundImage: `url(${logoFooter})`,
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    // logo: {
    //     width: 205
    // }
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    logo: {
      fontSize: '1.25rem',
      width: '6.25rem',
      height: '2.125rem',
      lineHeight: '2.125rem',
    },
    logoImg: {
      height: 'auto',
      width: 'auto',
      maxWidth: '10rem',
      marginRight: '0rem',
    },
    logoWrapper: {
      height: `calc(${topBarHeightMobile} - 2rem)`,
    },
  },
};

export default css;
