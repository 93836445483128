export enum ContractStatusType {
  AWAITING_SIGNATURE = 'waitingSignature',
  AWAITING_APPROVAL = 'waitingApproval',
  AWAITING_EXTRADITION = 'waitingExtradition',
  ACTIVE = 'active',
  OVERDUE = 'overdue',
  CLOSED = 'closed',
  SOLD = 'sold',
}

export const canBePayed = (status) => {
  switch (status) {
    case ContractStatusType.ACTIVE:
    case ContractStatusType.OVERDUE:
    case ContractStatusType.CLOSED:
    case ContractStatusType.SOLD:
      return true;
  }
  return false;
};

export const hasSchedule = (status) => {
  switch (status) {
    case ContractStatusType.CLOSED:
      return false;
  }
  return true;
};

export const hasFactSchedule = (status) => {
  switch (status) {
    case ContractStatusType.ACTIVE:
    case ContractStatusType.OVERDUE:
      return true;
  }
  return false;
};
