import { SCREEN_SIZE } from '@brainysoft/lk-components';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  price: {
    letterSpacing: -0.3,
    whiteSpace: 'nowrap',
  },
  priceFractional: {},
  currencyIcon: {
    marginLeft: '.25em',
    fontSize: '.85em',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
