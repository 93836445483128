import i18n from 'i18next';

export const trans = (ns, code) => {
  return i18n.exists(`custom__${ns}:${code}`) ? i18n.t(`custom__${ns}:${code}`) : i18n.t(`${ns}:${code}`);
};

export const tHelper: (code: string, options?: object) => string = (code, options = {}) => {
  const [namespace, key] = code.includes(':') ? code.split(':') : ['general', code];
  return i18n.exists(`custom__${namespace}:${key}`)
    ? i18n.t(`custom__${namespace}:${key}`, options)
    : i18n.t(`${namespace}:${key}`, options);
};

export const keyExistsHelper: (code: string) => boolean = (code) => {
  const [namespace, key] = code.includes(':') ? code.split(':') : ['general', code];
  return i18n.exists(`custom__${namespace}:${key}`) || i18n.exists(`${namespace}:${key}`);
};

export const tAsArrayHelper: (code: string, options?: object) => string[] = (code, options) => {
  const textContent = keyExistsHelper(code) ? tHelper(code, { ...(options ?? {}), returnObjects: true }) : code;
  return (Array.isArray(textContent) ? textContent : [textContent]) as string[];
};
