import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import Color from 'color';

const { blue, green, red, white, yellow, greyLight } = THEME_COLORS;

const successColor = green;
const waitingColor = yellow;
const loadingColor = blue;
const failColor = red;
const noStatusColor = greyLight;
const arrowColor = white;
const arrowThickness = 2; /* 2+ */
export const css = {
  resultIconWrapper: {
    margin: '1rem 0',
    display: 'flex',
    justifyContent: 'center',
  },
  checkmark: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '50%',
    stroke: arrowColor,
    strokeWidth: arrowThickness,
    strokeMiterlimit: 10,
    //box-shadow: inset 0 0 0 successColor;
    animation: 'stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
  },

  checkmark__circle: {
    strokeDasharray: 166,
    strokeDashoffset: 166,
    strokeWidth: arrowThickness,
    strokeMiterlimit: 10,
    stroke: successColor,
    fill: 'none',
    animation: 'stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
  },

  checkmark__check: {
    transformOrigin: '50% 50%',
    strokeDasharray: 48,
    strokeDashoffset: 48,
    stroke: successColor,
    animation: 'stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards',
  },

  clock: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '50%',
    stroke: arrowColor,
    strokeWidth: arrowThickness,
    strokeMiterlimit: 10,
    //box-shadow: inset 0 0 0 successColor;
    animation: 'fill 0.4s ease-in-out 0.4s forwards',
  },

  clock__circle: {
    strokeDasharray: 166,
    strokeDashoffset: 166,
    strokeWidth: arrowThickness,
    strokeMiterlimit: 10,
    stroke: waitingColor,
    fill: 'none',
    animation: 'stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
  },

  clock__hour: {
    transformOrigin: '50% 50%',
    strokeDasharray: 0,
    strokeDashoffset: 0,
    strokeWidth: arrowThickness,
    stroke: waitingColor,
    fill: 'none',
    animation: 'rotate 60s linear infinite, stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
  },

  clock__minute: {
    transformOrigin: '50% 50%',
    strokeDasharray: 0,
    strokeDashoffset: 0,
    strokeWidth: arrowThickness,
    stroke: waitingColor,
    fill: 'none',
    animation: 'rotate 12s linear infinite, stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
  },

  close: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '50%',
    stroke: arrowColor,
    strokeWidth: arrowThickness,
    strokeMiterlimit: 10,
    //box-shadow: inset 0 0 0 successColor;
    animation: 'fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both',
  },

  close__circle: {
    strokeDasharray: 166,
    strokeDashoffset: 166,
    strokeWidth: arrowThickness,
    strokeMiterlimit: 10,
    stroke: failColor,
    fill: 'none',
    animation: 'stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
  },

  close__line: {
    transformOrigin: '50% 50%',
    strokeDasharray: 48,
    strokeDashoffset: 48,
    stroke: failColor,
    animation: 'stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards',
  },

  loading: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '50%',
    stroke: arrowColor,
    strokeWidth: arrowThickness,
    strokeMiterlimit: 10,
    //box-shadow: inset 0 0 0 successColor;
  },

  loading__circle: {
    stroke: 'none',
    fill: Color(loadingColor).lighten(0.9).rgb().string(),
    opacity: 0.5,
  },

  loading__circle_sector: {
    transformOrigin: '50% 50%',
    stroke: 'none',
    fill: loadingColor,
    animation: 'rotate 2s linear infinite, stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
  },

  loading__circle_over: {
    fill: white,
  },

  no_status: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '50%',
    stroke: arrowColor,
    strokeWidth: arrowThickness,
    strokeMiterlimit: 10,
    //box-shadow: inset 0 0 0 successColor;
    animation: 'fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both',
  },

  no_status__circle: {
    strokeDasharray: 166,
    strokeDashoffset: 166,
    strokeWidth: arrowThickness,
    strokeMiterlimit: 10,
    stroke: noStatusColor,
    fill: 'none',
    animation: 'stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
  },

  no_status__line: {
    transformOrigin: '50% 50%',
    strokeDasharray: 48,
    strokeDashoffset: 48,
    stroke: noStatusColor,
    animation: 'stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
  },

  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
};

export default css;
