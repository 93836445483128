import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { THEME_COLORS } from '@brainysoft/lk-custom/colors';
import { authBackground, VARIABLES } from '@brainysoft/lk-custom/variables';

const { generalBorder, generalBorderRadius, topBarHeight, generalTransition } = VARIABLES;
const { black, greyLightText, greyText, primary, white } = THEME_COLORS;

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  authPageWrap: {
    width: '100%',
    paddingLeft: '50%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    position: 'relative',
  },
  authBackgroundImage: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: '50%',
    background: `url(${authBackground}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      // background: 'linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)',
      background: 'linear-gradient(-15deg, rgba(0,0,0,0) 70%, rgba(250,250,255,0.8) 100%)',
    },
  },
  authDisclaimer: {
    flex: 0,
    width: '100%',
    margin: 0,
    padding: '1rem 3rem 2rem',
    position: 'relative',
    background: 'rgb(255,255,255)',
    textAlign: 'left',
    '& .header': {
      margin: '0 auto 0.75rem',
    },
    '& .text': {
      fontSize: '.875rem',
      minHeight: 'calc(1.3 * 0.875rem)',
      lineHeight: '1.3',
    },
  },
  pageTitle: {
    color: black,
    lineHeight: '1.125',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    margin: [0, 0, '1.5rem'],
    textAlign: 'left',
  },
  pageSubTitle: {
    lineHeight: 1.15,
    fontSize: '1rem',
    margin: '0 auto 1rem',
    textAlign: 'left',
    color: greyText,
  },
  pageWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: `-${topBarHeight}`,
  },
  content: {
    marginBottom: '2rem',
  },
  modal: {
    maxWidth: 'auto',
    flex: 1,
    borderRadius: generalBorderRadius,
    boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.05)',
    background: white,
    padding: '3rem',
    margin: 0,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'relative',
  },
  formRow: {
    marginBottom: '1.25rem',
    position: 'relative',
  },
  phoneRow: {
    position: 'relative',
    '& .react-tel-input': {
      display: 'block',
      width: 350,
    },
    '& .react-tel-input input[type=tel]': {
      paddingLeft: '3rem',
      borderLeftWidth: 1,
      borderRadius: generalBorderRadius,
      '&:hover': {
        borderColor: primary,
      },
      '&:focus': {
        borderColor: primary,
        boxShadow: '0 0 0 2px rgba(0,120,220, 0.2)',
      },
    },
    '& .react-tel-input .flag-dropdown': {
      position: 'absolute',
      borderColor: 'transparent',
      background: 'transparent',
    },
    '& .react-tel-input .flag-dropdown .country-list': {
      width: 350,
    },
  },
  authFormWrapper: {
    maxWidth: 320,
    width: '100%',
  },
  nextButtonWrapper: {
    display: 'block',
    marginBottom: '1rem',
  },
  regButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  smsButtonWrapper: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  codeFieldWrapper: {
    '& .ant-collapse-content': {
      marginTop: '1rem !important',
    },
  },
  enterButtonWrapper: {
    display: 'flex',
    justifyContent: 'left',
  },
  getSmsButton: {
    fontSize: '13px !important',
    display: 'flex',
    alignItems: 'center',
  },
  codeRow: {
    position: 'relative',
    '& .ant-collapse-content': {
      marginTop: '1rem',
    },
  },
  authInput: {
    display: 'block',
    borderRadius: generalBorderRadius,
    border: generalBorder,
    background: white,
    fontSize: '1rem',
    padding: '1.25rem',
    width: '100%',
    boxSizing: 'border-box',
    outline: 'none',
    '&::placeholder': {
      color: greyLightText,
    },
  },
  label: {
    display: 'block',
    fontSize: '0.8125rem',
    color: greyText,
  },
  errorMessage: {
    display: 'block',
    color: 'red',
    fontSize: '.75rem',
  },
  antdModal: {
    '& .ant-modal-title .title': {
      margin: 0,
    },
    '& .ant-modal-body': {
      fontSize: '0.9125rem',
    },
  },
  registrationLink: {
    cursor: 'pointer',
  },
  externalAuthWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    border: generalBorder,
    borderRadius: 8,
    transition: generalTransition,
    '&:hover': {
      borderColor: primary,
      cursor: 'pointer',
    },
  },
  externalAuthTitle: {
    marginRight: '1rem',
  },
  externalAuthContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  externalAuthItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gosuslugiLabel: {
    marginBottom: '.5rem',
    fontSize: '.875rem',
  },
  gosuslugiContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '.5rem',
    '& svg': {
      width: 150,
      height: 'auto',
    },
  },
  gosuslugiTitle: {
    textAlign: 'center',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    modal: {
      width: 'auto',
      maxWidth: 'auto',
      padding: '2rem',
    },
    nextButtonWrapper: {
      display: 'block',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    authPageWrap: {
      paddingLeft: '0',
      paddingBottom: '1rem',
      margin: '0 auto',
      alignItems: 'center',
      justifyContent: 'center',
      '&::before': {
        content: 'none',
      },
    },
    authBackgroundImage: {
      display: 'none',
    },
    authDisclaimer: {
      width: 'calc(100% - 3rem)',
      margin: '0 auto',
      flex: 0,
      padding: '1.5rem 0 0',
      textAlign: 'center',
      background: 'transparent',
      color: greyText,
      opacity: '.75',
      '& .text': {
        fontSize: '.8125rem',
        minHeight: 'calc(1.3 * 0.8125rem)',
      },
    },
    pageWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: `calc(-0.5 * ${topBarHeight})`,
    },
    modal: {
      width: 'calc(100% - 3rem)',
      alignItems: 'center',
      padding: '0.875rem',
      flex: 0,
      margin: '0 auto',
      borderRadius: generalBorderRadius,
    },
    content: {
      padding: [0, '1.5rem'],
      boxSizing: 'border-box',
      marginTop: '15%',
    },
    sendSMS: {
      position: 'static',
      margin: ['1.5rem', 'auto', 0],
    },
    pageTitle: {
      fontSize: '1rem',
      margin: [0, 0, '1rem'],
      textAlign: 'center',
    },
    authInput: {
      padding: ['0.6875rem', '1.25rem'],
      fontSize: '0.9375rem',
    },
    formRow: {
      '& .react-tel-input input[type=tel]': {
        padding: ['0.6875rem', '1.25rem', '0.6875rem', '3.125rem'],
        fontSize: '0.9375rem',
      },
    },
    pageSubTitle: {
      width: 'auto',
      textAlign: 'center',
    },
    smsButtonWrapper: {
      justifyContent: 'center',
    },
    nextButtonWrapper: {
      display: 'flex !important',
      justifyContent: 'center',
    },
    regButtonWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    enterButtonWrapper: {
      display: 'flex',
      justifyContent: 'center !important',
    },
    getSmsButton: {
      whiteSpace: 'normal',
      height: 'auto !important',
      paddingTop: '.25rem !important',
      paddingBottom: '.25rem !important',
    },
  },
  [`@media (min-width: ${SCREEN_SIZE.WIDESCREEN}px)`]: {
    authPageWrap: {
      width: '100%',
      paddingLeft: '50%',
      flex: 1,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'stretch',
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
    },
  },
};

export default css;
