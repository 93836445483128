export enum PeriodType {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  TRANCHES = 'TRANCHES',
}

export const periodTypeWeight = (type: PeriodType) => {
  switch (type) {
    case PeriodType.WEEKS:
      return 7;
    case PeriodType.MONTHS:
      return 30;
  }
  return 1;
};
