import cache from './cache';
import config from '../config';
import API from './API';
import Entity from './entity';
import fetch from './fetchWithToken';
import { RepaymentType } from '../../enums';

class Contracts extends Entity {
  public async isValid(id: number): Promise<Record<string, boolean>> {
    this.storeEntity = 'contract';
    const result = await this.fetch('', `/${id}/valid`);
    return result;
  }

  public async getContractDetails(id: number) {
    this.storeEntity = 'contract';
    const result = await this.fetch('', `/${id}`);
    return result;
  }

  public async getContractFactSchedule(id: number) {
    this.storeEntity = 'contract';
    const result = await this.fetch('', `/${id}/fact-schedule`);
    return result;
  }

  public async getContractPlanSchedule(id: number) {
    this.storeEntity = 'contract';
    const result = await this.fetch('', `/${id}/plan-schedule`);
    return result;
  }

  public async getContractRepaymentBag(id: number, type: RepaymentType) {
    this.storeEntity = 'contract';
    const result = await this.fetch('', `/${id}/repayment-bag?type=${type}`);
    return result;
  }

  public async isProlongationAvailable(id: number) {
    this.storeEntity = 'contract';
    const result = await this.fetch('', `/${id}/prolongation-avalability`);
    return result;
  }

  public async interactMessages(id: number) {
    this.storeEntity = 'contract';
    const result = await this.fetch('', `/${id}/messages`);
    return result;
  }
}

export const contractsService = new Contracts(fetch, API, cache, config);
