import { useEffect } from 'react';
import { setUser, setContext } from '@sentry/react';

export const SentrySession = ({ consumerUuid, sessionId, isLoading }) => {
  useEffect(() => {
    if (isLoading) return;

    if (consumerUuid) {
      const consumerKey = `${consumerUuid || 'no_consumer_uuid'}`;
      setUser({
        id: consumerKey,
      });
      const sessionContext = {
        id: sessionId,
      };
      setContext('session', sessionContext);
    } else {
      setUser(null);
      setContext('session', null);
    }
  }, [consumerUuid, sessionId, isLoading]);

  return null;
};
