import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import ru from './src/lang/ru/general.json';
import en from './src/lang/en/general.json';
import { LOCALE } from '@brainysoft/lk-custom/config';
import { getTenantModules } from '@brainysoft/lk-custom-kernel/utils/get-tenant-modules';

i18next.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    ru: { translation: ru },
  },
  lng: LOCALE,
  fallbackLng: 'ru-RU',
});

const translationsRuContext = (require as any).context('./src/lang/ru', true, /\.(json)$/);
const translationsEnContext = (require as any).context('./src/lang/en', true, /\.(json)$/);

importBundles('ru', '', translationsRuContext);
importBundles('en', '', translationsEnContext);

const customBundles = getTenantModules('translations');

// const customTranslationsRuContext = (require as any).context('./../translations/ru', true, /\.(json)$/);
// const customTranslationsEnContext = (require as any).context('./../translations/en', true, /\.(json)$/);

if (customBundles?.translations?.ru) importBundles('ru', 'custom__', customBundles?.translations?.ru);
if (customBundles?.translations?.en) importBundles('en', 'custom__', customBundles?.translations?.en);
if (customBundles?.translations?.ge) importBundles('ge', 'custom__', customBundles?.translations?.ge);

function importBundles(lang, prefix = '', context) {
  if (typeof context.keys === 'function') {
    //получен контекст вебпака
    context.keys().forEach((key) => {
      const name = key.replace('./', '').replace('.json', '');
      const bundle = context(key);
      i18next.addResourceBundle(lang, `${prefix}${name}`, bundle);
    });
  } else if (typeof context === 'object') {
    Object.keys(context).forEach((key) => {
      //получен модуль
      const bundle = context[key];
      i18next.addResourceBundle(lang, `${prefix}${key}`, bundle);
    });
  }
}
