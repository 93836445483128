import { SCREEN_SIZE } from '@brainysoft/lk-components';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  tag: {
    fontSize: '.75rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    backButton: {
      width: '0.625rem',
      height: '1.5625rem',
      margin: 0,
    },
  },
};

export default css;
