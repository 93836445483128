import { SCREEN_SIZE } from '@brainysoft/lk-components';

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = {
  pageWrapper: {
    background: '#e6e7e8',
    maxWidth: 1200,
    margin: 'auto',
  },
  leftSide: {
    display: 'inline-block',
    width: '25%',
    padding: ['1.875rem', 0, '1.875rem', '2rem'],
    boxSizing: 'border-box',
    verticalAlign: 'top',
  },
  rightSide: {
    display: 'inline-block',
    width: '75%',
    padding: ['1.875rem', '2rem', '1.875rem', '2%'],
    boxSizing: 'border-box',
    verticalAlign: 'top',
  },
  wrap: {
    background: '#ffffff',
    borderRadius: '0.375rem',
  },
  pageTitle: {
    fontSize: '2.25rem',
    fontWeight: 'bold',
    color: '#000000',
    borderBottom: 'solid 1px #e6e7e8',
    padding: '1rem 2rem',
    margin: 0,
    position: 'relative',
  },
  tableWrap: {
    margin: ['4rem', '1rem', 0],
    paddingBottom: '1.5625rem',
    textAlign: 'center',
  },
  backBtn: {
    position: 'absolute',
    left: 16,
    top: 20,
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {
    // leftSide: {
    //     display: 'none'
    // },
    // rightSide: {
    //     width: '100%',
    //     padding: [20, 15]
    // },
    // greeting: {
    //     margin: '0 0 15px',
    //     fontSize: 30
    // }
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    leftSide: {
      display: 'none',
    },
    rightSide: {
      width: '100%',
      padding: ['1.25rem', '0.9375rem'],
    },
    tableWrap: {
      margin: '0.625rem',
    },
    pageTitle: {
      fontSize: '1.125rem',
      padding: ['0.8125rem', '1rem', '0.8125rem', '2.25rem'],
    },
    backBtn: {
      top: 12,
    },
  },
};

export default css;
