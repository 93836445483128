import * as React from 'react';
import { createContainer } from 'unstated-next';
// eslint-disable-next-line no-unused-vars
import { DisplayModeType } from '../../enums';

export type ContextState = {
  displayMode: DisplayModeType;
  activePage: string;
  isPageLoading: boolean;
};

export type ContextMethods = {
  setDisplayMode: (mode: DisplayModeType) => void;
  setActivePage: (page: string) => void;
  setIsPageLoading: (isLoading: boolean) => void;
};

function Reducer() {
  const [displayMode, setDisplayMode] = React.useState<DisplayModeType>(DisplayModeType.DESKTOP);
  const [activePage, setActivePage] = React.useState<string>('main');
  const [isPageLoading, setIsPageLoading] = React.useState<boolean>(true);

  const state = { displayMode, activePage, isPageLoading };
  const methods = { setDisplayMode, setActivePage, setIsPageLoading };

  return { state, methods };
}

export const AppContext = createContainer<
  {
    state: ContextState;
    methods: ContextMethods;
  },
  ContextState
>(Reducer);
