import { Components, Global, Form } from '@brainysoft/lk-custom/theme';
import { get } from 'lodash';
import { createUseStyles } from 'react-jss';

export const useThemeStyles = (moduleName: string, props: any = {}) => {
  // return {};
  const dottedModuleName = moduleName.replace('/', '.');
  // const styles = get(STYLES, dottedModuleName);
  const styles = get(
    {
      ...Components,
      Form: Form,
    },
    dottedModuleName
  );

  const useStyles = createUseStyles(styles);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useStyles ? (useStyles(props) as any) : ({} as any);
};

export const themeGlobals = () => {
  // return {};
  return Global.APP_STYLES;
};
